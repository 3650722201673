<template>
  <div class="fieldset">
    <label>
      <b v-if="showNotice" class="notice"> * </b>
      {{ title }}
      <span class="text-muted">{{ subTitle }}</span>
    </label>
    <div class="input-telephone">
      <input
        class="input-sm"
        type="text"
        placeholder="區號"
        autocomplete="off"
        :name="payerAreaName"
        v-model="hookAreaModel"
        v-validate="'local-phone-area'"
        maxlength="4"
        :disabled="isRtype" />
      <input
        class="input-lg"
        type="text"
        placeholder="電話號碼"
        autocomplete="off"
        :name="payerNumberName"
        v-model="hookNumberModel"
        v-validate="'local-phone'"
        maxlength="8"
        :disabled="isRtype" />
      <input
        class="input-md"
        type="text"
        placeholder="分機"
        autocomplete="off"
        :name="payerExtName"
        v-model="hookExtModel"
        v-validate="'local-phone-ext'"
        maxlength="6"
        :disabled="isRtype" />
    </div>
    <p v-if="errors.has(payerAreaName)" class="error">* {{ errors.first(payerAreaName) }}</p>
    <p v-if="errors.has(payerNumberName)" class="error">* {{ errors.first(payerNumberName) }}</p>
    <p v-if="errors.has(payerExtName)" class="error">* {{ errors.first(payerExtName) }}</p>
  </div>
</template>

<script>
import { commitMutation, renderState } from '@/helpers/vuex';

const props = {
  title: {
    type: String,
    default: '市話',
  },
  /** 標題 title 是否加上必填 notice */
  notice: {
    type: Boolean,
    default: false,
  },
  subTitle: {
    type: String,
    default: '',
  },
  /** 區碼, 電話號碼, 分機 name */
  payerAreaName: {
    type: String,
    default: 'areaName',
  },
  payerNumberName: {
    type: String,
    default: 'numberName',
  },
  payerExtName: {
    type: String,
    default: 'extName',
  },
  hookType: {
    type: String,
    default: 'emit',
  },
  /**
   * hook 回傳
   * 若 hookType='emit' 時 ['回傳用 事件名稱', '起始預設 input value'], 父層再用 v-on:事件名稱 接住
   * 若 hookType='store' 時 ['回傳用 Store Module Name', '回傳用 store name']
   */
  hookStoreWithArea: {
    type: Array,
    default: ['areaInput', ''],
  },
  hookStoreWithNumber: {
    type: Array,
    default: ['numberInput', ''],
  },
  hookStoreWithExt: {
    type: Array,
    default: ['extInput', ''],
  },
  isRtype: {
    type: Boolean,
    default: false,
  },
};

const data = function () {
  return {
    defaultValueWithArea: '',
    defaultValueWithNumber: '',
    defaultValueWithExt: '',
  };
};

/** computed: notice */
const showNotice = function () {
  return this.notice;
};

const payerArea = {
  get() {
    return renderState(this.hookStoreWithArea[0], 'localPhoneArea');
  },
  set(value) {
    commitMutation(this.hookStoreWithArea[0], 'localPhoneArea', value);
  },
};
const payerNumber = {
  get() {
    return renderState(this.hookStoreWithExt[0], 'localPhone');
  },
  set(value) {
    commitMutation(this.hookStoreWithExt[0], 'localPhone', value);
  },
};
const payerExt = {
  get() {
    return renderState(this.hookStoreWithNumber[0], 'localPhoneExt');
  },
  set(value) {
    commitMutation(this.hookStoreWithNumber[0], 'localPhoneExt', value);
  },
};

/**
 * methods: (generator) input 存取 store
 * @param {String} hookName props 內連動的 hook name, example: hookStoreWithArea
 * @param {String} defaultValue example this.defaultValueWithArea
 */
const genHookModel = function (hookName = 'hookStoreWithArea', defaultValue) {
  return {
    get() {
      /** 資料串接為 store 時的預設內容取自 store */
      if (this.hookType === 'store') {
        return renderState(this[hookName][0], this[hookName][1]);
      }

      /** 資料串接為 emit 時的預設內容 [hookName][1] 或空字串 */
      return defaultValue || '';
    },
    set(value) {
      /** 資料串接為 store 拋回時使用的 module name 及 state name */
      if (this.hookType === 'store') {
        commitMutation(this[hookName][0], this[hookName][1], value);

        /** 資料串接為 emit 拋回時使用的 emit event name */
      } else if (this.hookType === 'emit') {
        defaultValue = value;
        this.$emit(this[hookName][0], value);
      }
    },
  };
};

/** computed: Area input 存取 store */
const hookAreaModel = {
  get() {
    /** 資料串接為 store 時的預設內容取自 store */
    if (this.hookType === 'store') {
      return renderState(this.hookStoreWithArea[0], this.hookStoreWithArea[1]);
    }

    /** 資料串接為 emit 時的預設內容 hookStoreWithArea[1] 或空字串 */
    return this.defaultValueWithArea || '';
  },
  set(value) {
    /** 資料串接為 store 拋回時使用的 module name 及 state name */
    if (this.hookType === 'store') {
      commitMutation(this.hookStoreWithArea[0], this.hookStoreWithArea[1], value);

      /** 資料串接為 emit 拋回時使用的 emit event name */
    } else if (this.hookType === 'emit') {
      this.defaultValueWithArea = value;
      this.$emit(this.hookStoreWithArea[0], value);
    }
  },
};

/** computed: Number input 存取 store */
const hookNumberModel = {
  get() {
    /** 資料串接為 store 時的預設內容取自 store */
    if (this.hookType === 'store') {
      return renderState(this.hookStoreWithNumber[0], this.hookStoreWithNumber[1]);
    }

    /** 資料串接為 emit 時的預設內容 hookStoreWithNumber[1] 或空字串 */
    return this.defaultValueWithNumber || '';
  },
  set(value) {
    /** 資料串接為 store 拋回時使用的 module name 及 state name */
    if (this.hookType === 'store') {
      commitMutation(this.hookStoreWithNumber[0], this.hookStoreWithNumber[1], value);

      /** 資料串接為 emit 拋回時使用的 emit event name */
    } else if (this.hookType === 'emit') {
      this.defaultValueWithNumber = value;
      this.$emit(this.hookStoreWithNumber[0], value);
    }
  },
};

/** computed: Ext input 存取 store */
const hookExtModel = {
  get() {
    /** 資料串接為 store 時的預設內容取自 store */
    if (this.hookType === 'store') {
      return renderState(this.hookStoreWithExt[0], this.hookStoreWithExt[1]);
    }

    /** 資料串接為 emit 時的預設內容 hookStoreWithExt[1] 或空字串 */
    return this.defaultValueWithExt || '';
  },
  set(value) {
    /** 資料串接為 store 拋回時使用的 module name 及 state name */
    if (this.hookType === 'store') {
      commitMutation(this.hookStoreWithExt[0], this.hookStoreWithExt[1], value);

      /** 資料串接為 emit 拋回時使用的 emit event name */
    } else if (this.hookType === 'emit') {
      this.defaultValueWithExt = value;
      this.$emit(this.hookStoreWithExt[0], value);
    }
  },
};

export default {
  name: 'telPhoneRow',
  inject: ['$validator'],
  props,
  data,
  computed: {
    showNotice,
    payerArea,
    payerNumber,
    payerExt,
    hookAreaModel,
    hookNumberModel,
    hookExtModel,
  },
  methods: {
    genHookModel,
    setDefaultValue() {
      if (this.hookType === 'emit') {
        this.defaultValueWithArea = this.hookStoreWithArea[1] || '';
        this.defaultValueWithNumber = this.hookStoreWithNumber[1] || '';
        this.defaultValueWithExt = this.hookStoreWithExt[1] || '';
      }
    },
  },
  mounted() {
    this.setDefaultValue();
  },
};
</script>
