<template>
  <div class="fieldset mp-note">
    <!-- 街口支付 -->
    <div v-if="isShowNotice(jkosPayment)">
      <div class="mp_t">街口支付說明</div>
      <ul class="has-type-disc text-muted">
        <li>使用街口支付恕不適用分期付款。</li>
        <li>消費可使用街口幣折抵，折抵上限為結帳總金額 30 %。</li>
        <li>街口支付接受街口聯名卡及帳戶付款。</li>
        <li>付款額度限制：每筆 5 萬/每日 10 萬/每月 20 萬。</li>
      </ul>
    </div>
    <!-- Pi 拍錢包 -->
    <div v-if="isShowNotice(PiPayment)">
      <div class="mp_t">Pi拍錢包付款說明</div>
      <ul class="has-type-disc text-muted">
        <li>使用Pi拍錢包恕不適用分期付款。</li>
        <li>付款額度限制：每筆交易上限為 10 萬。</li>
        <li>Pi拍錢包付款有效時間：於訂單成立後 20 分鐘內，須完成付款。</li>
      </ul>
    </div>
    <!-- 台灣 Pay -->
    <div v-if="isShowNotice(TWPayment)">
      <div class="mp_t">台灣Pay付款說明</div>
      <ul class="has-type-disc text-muted">
        <li>使用台灣Pay恕不適用分期付款。</li>
        <li>台灣Pay限使用金融卡/帳戶付款。</li>
        <li>台灣Pay付款有效時間：於訂單成立後 10 分鐘內，須完成付款。</li>
        <li>單筆訂單金額上限依各銀行單筆/單日交易限制。</li>
      </ul>
    </div>
    <div v-if="isShowNotice(PlusPayPayment)">
      <div class="mp_t">全盈+PAY付款說明</div>
      <ul class="has-type-disc text-muted">
        <li>使用全盈+PAY恕不適用分期付款。</li>
        <li>全盈+PAY接受信用卡及帳戶付款。</li>
        <li>消費可使用全家點數全額折抵。</li>
        <li>付款額度限制：依全盈+PAY官網說明。</li>
      </ul>
    </div>
    <!-- Apple Pay -->
    <div v-if="isShowNotice(ApplePayPayment)">
      <div class="mp_t">ApplePay付款說明</div>
      <ul class="has-type-disc text-muted">
        <li>限用 iOS 裝置(須軟體版本 13 以上)及 Safari 瀏覽器。</li>
        <li>使用 Apple Pay 恕不適用分期付款。</li>
      </ul>
    </div>
    <!-- 悠遊付 -->
    <ul v-if="isShowNotice(EasyPayPayment)" class="has-type-disc text-muted">
      <li>使用悠遊付恕不適用分期付款。</li>
      <li>悠遊付限使用信用卡付款</li>
      <li>於訂單成立後 20 分鐘內須完成付款</li>
      <li>付款額度限制：單月 30 萬。</li>
    </ul>
  </div>
</template>

<script>
import { paymentEnum } from '@/constant/payment-info';

export default {
  name: 'payment-type-selector-notice',
  inject: ['$validator'],
  props: {
    paymentType: Number,
  },
  created() {
    Object.keys(paymentEnum).forEach((payment) => (this[payment] = paymentEnum[payment]));
  },
  methods: {
    isShowNotice(payment) {
      return this.paymentType === payment;
    },
  },
  mounted() {
    this.$validator.validate('*');
  },
};
</script>
