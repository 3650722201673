<template>
  <div class="check-cell-content btl" v-if="showList">
    <div class="panel-body">
      <div class="check-table pay-installment sticky">
        <div v-for="(item, index) in getZingalaData" :key="index" class="check-cell">
          <div class="checkbox">
            <input
              type="radio"
              name="zingalaType"
              v-model="zingalaValue"
              :value="index"
              :id="getZingalaId(item)"
              v-validate="'required'" />
          </div>
          <div class="text">
            <label :for="getZingalaId(item)">
              <b class="price">{{ item.period }}</b>
              &nbsp;期&nbsp;
              <b class="price">{{ $filters.percent(item.rate) }}</b>
              &nbsp;利率，每期約&nbsp;
              <b class="price">{{ $filters.dollar(item.perMoney) }}</b>
            </label>
          </div>
        </div>
      </div>
      <p class="error" v-if="errors.has('installment')">* 請選擇分期付款方式</p>
      <div class="fieldset">
        <p class="divider notice fn-sm">* 每期金額僅為試算，分期實付金額以無卡分期服務商議定內容為準</p>
        <label for="zingala-terms" class="pl-box">
          <input
            type="checkbox"
            id="zingala-terms"
            name="agreeZingalaCheck"
            v-model="isCheckZingalaTerms"
            v-validate="'required'" />
          我已閱讀及明白
          <a class="underline ml-0" href="javascript:;" @click="openModal('ZingalaNoticeModal')"> 付款說明 </a>
          並同意資料依下列方式使用
          <p class="spacing top sm">
            本人已明白並同意神腦生活在完成本訂單之目的下，將訂單相關資料轉予銀角零卡-無卡分期仲信資融股份有限公司進行蒐集、處理及利用，並理解後續仲信資融將與本人進行資料之確認、核對及更正。
          </p>
        </label>
      </div>
    </div>
    <zingala-notice-modal />
  </div>
</template>

<script>
import { dollar, percent } from '@/helpers/format/dollar';
import { renderState, createComputed } from '@/helpers/vuex';
import { openModal } from '@/helpers/modal';
import ZingalaNoticeModal from '@/components/common/modals/zingala-notice-modal.vue';

const computed = {
  getZingalaData() {
    return renderState('ZingalaInfo', 'installments');
  },
  showList() {
    return this.getZingalaData.length > 0;
  },
  zingalaValue: createComputed('ZingalaInfo', 'installmentIndex'),
  isCheckZingalaTerms: createComputed('ZingalaInfo', 'isCheckZingalaTerms'),
};

const filters = {
  dollar,
  percent,
};

const methods = {
  getZingalaId(item) {
    return `zingala_${item.period}_${item.rate}`;
  },
  openModal,
};

export default {
  name: 'zingala-payment',
  inject: ['$validator'],
  components: {
    ZingalaNoticeModal,
  },
  computed,
  filters,
  methods,
  mounted() {
    this.$validator.validate('*');
  },
};
</script>
