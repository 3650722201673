<template>
  <div>
    <h3 class="fn-lg blue text-center spacing top md">手機號碼認證</h3>
    <div class="border bottom"></div>
    <h4 class="text-center spacing top md">您的手機號碼</h4>
    <h4 class="text-center spacing top sm bottom sm value fn-lg">{{ mobilePhone }}</h4>
    <h5 class="text-center">請查看您的手機簡訊，並將收到的驗證碼填入且送出，以完成手機驗證作業</h5>
    <form class="form_2R" name="step2-member-registration" @submit.prevent="verifyAndGetMemberRegistration">
      <div class="fieldset">
        <label class="fn-md">驗證碼</label>
        <input
          v-model.trim="authCode"
          type="text"
          class="form-control"
          placeholder="請輸入您的手機驗證碼"
          autocomplete="one-time-code"
          maxlength="10" />
        <a v-if="isTimerBtn" class="divider text-muted text-center" href="javascript:void(0);">
          <time class="countdown">{{ countDown }}</time>
          秒後才能重新發送簡訊
        </a>
        <a
          v-if="isSendCodeBtn"
          class="divider text-muted text-center btn-resend"
          href="javascript:void(0);"
          @click="$emit('get-sms-send')">
          重新發送驗證簡訊
        </a>
      </div>
      <p class="error notice" v-if="validateErrorMsg">
        {{ validateErrorMsg }}
      </p>
      <h5 class="divider horizontal">注意事項：</h5>
      <ol class="has-type-decimal sticky list-2R">
        <li>請確認手機號碼是否輸入正確，是否拒收企業廣告簡訊。</li>
        <li>每日驗證碼最多申請 3 次。</li>
        <li>驗證碼於發送後 30 分鐘後自動失效。</li>
        <li>驗證成功系統將寄發預設密碼簡訊。</li>
      </ol>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-disabled btn-round"
          data-dismiss="modal"
          @click="$emit('cancel')"
          :disabled="isTimerBtn">
          取消
        </button>
        <button type="submit" class="btn btn-query btn-round">下一步</button>
      </div>
    </form>
  </div>
</template>

<script>
import { createRegistrationItem, sendGA4sEvent } from '@/helpers/tracking/ga';
import { getState, createComputed, commitMutation } from '@/helpers/vuex';
import { memberRegistrationProcess } from '@/mixins/cart/finished/member-registration-process';

const verifyAndGetMemberRegistration = function () {
  if (this.authCode === '') {
    commitMutation('RegisterSenaoMembership', 'validateErrorMsg', '請填寫正確的驗證碼');
    return;
  }
  sendGA4sEvent('sign_up_OTP', createRegistrationItem('簡訊驗證完成', 'cart'));
  this.$emit('get-member-registration');
};

export default {
  name: 'step2-member-registration',
  mixins: [memberRegistrationProcess],
  computed: {
    mobilePhone: getState('OrderInfo', 'mobilePhone'),
    authCode: createComputed('RegisterSenaoMembership', 'authCode'),
    validateErrorMsg: getState('RegisterSenaoMembership', 'validateErrorMsg'),
  },
  mounted() {
    // 進畫面即開始倒數
    this.countDownTimer();
  },
  methods: {
    verifyAndGetMemberRegistration,
  },
};
</script>
