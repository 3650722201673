<template>
  <div class="check-cell-content">
    <div class="panel-body">
      <div class="check-table pay-installment sticky">
        <div class="check-cell" v-for="(item, index) in invoiceDonateIds" :key="index">
          <div class="checkbox">
            <input
              type="radio"
              :id="getId(item)"
              name="order_invoice_donate_id"
              :value="item.donateId"
              v-model="donateInvoice"
              v-validate.initial="validateRule" />
          </div>
          <div class="text">
            <label :for="getId(item)">{{ item.donateName }} (捐贈碼：{{ item.donateId }})</label>
          </div>
        </div>
        <p v-if="errors.has('order_invoice_donate_id')" class="error">
          {{ errors.first('order_invoice_donate_id') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';

export default {
  name: 'invoice-donation',
  inject: ['$validator'],
  methods: {
    getId(item) {
      return `order_invoice_donate_id_${item.donateId}`;
    },
  },
  computed: {
    donateInvoice: createComputed('InvoiceInfo', 'donateInvoice'),
    invoiceDonateIds: createComputed('InvoiceInfo', 'donateInvoiceList'),
    validateRule() {
      const codeList = this.invoiceDonateIds.map((v) => v.donateId).join(',');
      return `required|included:${codeList}`;
    },
  },
  mounted() {
    this.donateInvoice = this.invoiceDonateIds[0].donateId;
    this.$validator.validate('order_invoice_donate_id');
  },
};
</script>
