<template>
  <div :class="{ 'check-cell-content btl': !isRtype }">
    <creditcard-info :is-rtype="isRtype"></creditcard-info>
    <div class="fieldset" v-if="!isRtype">
      <p class="divider notice fn-sm">
        * 貼心提醒：當使用「信用卡紅利折抵」方式付款，若您單筆訂單中有多品項商品，後續辦理退貨僅能受理整筆訂單退貨。
      </p>
    </div>
  </div>
</template>

<script>
import CreditcardInfo from '@/components/mobile/step2/payment-type/common/creditcard-info.vue';

export default {
  name: 'creditcard-bonus',
  inject: ['$validator'],
  props: {
    isRtype: Boolean,
  },
  components: {
    CreditcardInfo,
  },
  mounted() {
    this.$validator.validate('*');
  },
};
</script>
