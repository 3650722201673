<template>
  <div class="fieldset">
    <label>
      <b v-if="showNotice" class="notice"> * </b>
      {{ title }}
      <span class="text-muted">&nbsp;{{ subTitle }}</span>
      <a
        v-if="!isRtype && isShowImportOrderInfo"
        href="javascript:void(0);"
        class="underline pull-right"
        tabindex="-1"
        @blur="handleBlur"
        @click="copyBuyerInfo(hookStore[0])">
        同訂購人資訊
      </a>
    </label>
    <input
      v-if="isNotMasked"
      :ref="inputName"
      :type="inputType"
      :name="inputName"
      :placeholder="placeholder"
      :maxlength="maxlength"
      v-model="hookModel"
      autocomplete="off"
      v-validate.initial="validateRule"
      :disabled="isRtype" />
    <input v-else :ref="inputName" :type="'text'" v-model="maskedValue" @focus="isNotMasked = true" />
    <p v-if="errors.has(inputName)" class="error">* {{ errors.first(inputName) }}</p>
  </div>
</template>

<script>
/**
 * mobile input row
 *
 * example with store mode
    <inputRow
      :title="'中文姓名'"
      :notice="true"
      :placeholder="'請填寫中文全名'"
      :subTitle="'(請填信用卡持卡人姓名)'"
      :inputName="'payerName'"
      :hookType="'store'"
      :hookStore="['CardInfo', 'name']"
      :maxlength="6"
      :validateRule="'required'"
    ></inputRow>
  *
  * example with emit mode
    <inputRow
      :title="'中文姓名'"
      :notice="true"
      :placeholder="'請填寫中文全名'"
      :subTitle="'(請填信用卡持卡人姓名)'"
      :inputName="'payerName'"
      :hookType="'emit'"
      :hookStore="['hookEventName', '我的名字']"
      v-on:hookEventName="updatePayerNameValueMethod"
      :maxlength="6"
      :validateRule="'required'"
    ></inputRow>
 */
import { computed } from 'vue';
import { commitMutation, renderState } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';

const props = {
  /** 標題 title */
  title: {
    type: String,
    default: '欄位標題',
  },
  /** 標題 title 是否加上必填 notice */
  notice: {
    type: Boolean,
    default: false,
  },
  /** 小標 subTitle */
  subTitle: {
    type: String,
    default: '',
  },
  /** input type */
  inputType: {
    type: String,
    default: 'text',
  },
  /** input name */
  inputName: {
    type: String,
    default: 'name',
  },
  /** input attribute: placeholder */
  placeholder: {
    type: String,
    default: '',
  },
  /** input attribute: maxlangth */
  maxlength: {
    type: [Number, String],
    default: '',
  },
  /** 驗證規則 */
  validateRule: {
    type: String,
    default: '',
  },
  /** hook 的 store module name */
  module: {
    type: String,
    default: '',
  },
  /** 回傳方式: store, emit */
  hookType: {
    type: String,
    default: 'emit',
  },
  /**
   * hook 回傳
   * 若 hookType='emit' 時 ['回傳用 事件名稱', '起始預設 input value'], 父層再用 v-on:事件名稱 接住
   * 若 hookType='store' 時 ['回傳用 Store Module Name', '回傳用 store name']
   */
  hookStore: {
    type: Array,
    default: ['input', ''],
  },
  isRtype: {
    type: Boolean,
    default: false,
  },
  /** 只有在付款人姓名欄位才會出現"同訂購人資料" */
  isShowImportOrderInfo: {
    type: Boolean,
    default: false,
  },
};

const data = function () {
  return {
    defaultValue: '',
  };
};

/** computed: notice */
const showNotice = function () {
  return this.notice;
};

/** computed: defaultValue */
const getDefaultValue = {
  get() {
    return this.defaultValue;
  },
  set(value) {
    this.defaultValue = value;
  },
};

/** computed: input 存取 store */
const hookModel = {
  get() {
    /** 資料串接為 store 時的預設內容取自 store */
    if (this.hookType === 'store') {
      return renderState(this.hookStore[0], this.hookStore[1]);
    }

    /** 資料串接為 emit 時的預設內容 hookStore[1] 或空字串 */
    return this.getDefaultValue || '';
  },
  set(value) {
    /** 資料串接為 store 拋回時使用的 module name 及 state name */
    if (this.hookType === 'store') {
      commitMutation(this.hookStore[0], this.hookStore[1], value);

      /** 資料串接為 emit 拋回時使用的 emit event name */
    } else if (this.hookType === 'emit') {
      this.getDefaultValue = value;
      this.$emit(this.hookStore[0], value);
    }
  },
};

export default {
  name: 'inputRow',
  inject: ['$validator'],
  props,
  data,
  computed: {
    showNotice,
    getDefaultValue,
    hookModel,
  },
  methods: {
    setDefaultValue() {
      if (this.hookType === 'emit') {
        this.defaultValue = this.hookStore[1] || '';
      }
    },
  },
  mounted() {
    this.setDefaultValue();
  },
  created() {
    this.setIsNotMaskedByValue(this.hookModel);
  },
  setup(props) {
    const { maskedName, maskedEmail, maskedMobile, isNotMasked, handleBlur, copyBuyerInfo, setIsNotMaskedByValue } =
      useMask(props);
    const maskedValue = computed(() => {
      const maskedValues = {
        name: maskedName.value,
        mobilePhone: maskedMobile.value,
        email: maskedEmail.value,
      };
      const propName = props.hookStore[1];
      return maskedValues[propName] || '';
    });
    return { isNotMasked, maskedValue, handleBlur, copyBuyerInfo, setIsNotMaskedByValue };
  },
};
</script>
