<template>
  <panel-template :title="this.title">
    <template slot="panel-body">
      <!-- 勾選使用折抵 -->
      <discount-block></discount-block>
      <div class="fieldset" v-show="isShowPaymentMethod">
        <label style="padding-left: 0px">
          <payment-marquee></payment-marquee>
        </label>
        <div class="check-table paylist">
          <div v-for="(paymentItem, index) in payments" :key="index" :id="`paymentItem-${index}`">
            <div class="check-cell" :style="{ width: onlyOnePayment }">
              <div class="checkbox">
                <input
                  type="radio"
                  name="payment"
                  :id="paymentItem.paymentType"
                  v-model="payment"
                  :disabled="isDisabled(paymentItem)"
                  :value="paymentItem.paymentType"
                  @change="handlePaymentData(payment, index)" />
              </div>
              <div class="text">
                <label
                  :for="paymentItem.paymentType"
                  :class="[
                    'radio-inline spacing right',
                    isActiveTitle(paymentItem.paymentType),
                    isDisabledPayment(paymentItem.status),
                  ]">
                  {{ paymentItem.paymentName }}
                </label>
              </div>
            </div>
          </div>
          <div id="append-payment-detail">
            <component :is="renderMatchComponent()" :is-rtype="isRtype"></component>
          </div>
        </div>
      </div>
    </template>
  </panel-template>
</template>

<script>
import { prop, propEq, compose, not } from 'ramda';
import { createDynamicComponent, getState, dispatchAction, renderState, commitMutation } from '@/helpers/vuex';
import { paymentStatusEnum, paymentEnum } from '@/constant/payment-info';
import { componentLut } from '@/components/desktop/step1/payment-method/constant/payment-method.constant';
import { isZeroOrder } from '@/components/desktop/step1/payer-info/common/payer-info';
import paymentMarquee from '@/components/mobile/marquee.vue';
import panelTemplate from '@/components/mobile/elements/panel-template.vue';
import CreditcardOnetime from '@/components/mobile/step2/payment-type/component/creditcard-onetime.vue';
import CreditcardBonus from '@/components/mobile/step2/payment-type/component/creditcard-bonus.vue';
import CreditcardInstallment from '@/components/mobile/step2/payment-type/component/creditcard-installment.vue';
import MobilePayment from '@/components/mobile/step2/payment-type/component/mobile-payment.vue';
import ZingalaPayment from '@/components/mobile/step2/payment-type/component/zingala-payment.vue';
import DiscountBlock from '@/components/mobile/step2/discount-block.vue';

/**
 * 處理動態付款明細組件
 * @param {Number} originalIndex
 */
const handlePaymentView = (originalIndex) => {
  const paymentInfo = renderState('PaymentInfo', 'payments');
  if (paymentInfo.length <= 0) {
    return;
  }
  const paymentDetail = document.getElementById('append-payment-detail');
  const currentIndex = originalIndex + 1;
  const checkAppendBlock = currentIndex % 2 === 0 || currentIndex === paymentInfo.length;
  const getPaymentIndexId = checkAppendBlock ? originalIndex : currentIndex;
  // 為了解決開全頁 Modal 回來後 paymentDetail 跑版 issue
  commitMutation('PaymentInfo', 'userPickPaymentIndex', getPaymentIndexId);
  const currentDynamicComponent = document.getElementById(`paymentItem-${getPaymentIndexId}`);
  currentDynamicComponent.appendChild(paymentDetail);
};

/**
 * 信用卡分期 || 銀角中租零卡分期 || open mobile-payment component
 * @param {Number} payment
 * @param {Number} index
 */
const handlePaymentData = (payment, index) => {
  handlePaymentView(index);
  const hasCvcValue = renderState('CardInfo', 'cvc');
  if (hasCvcValue) {
    commitMutation('CardInfo', 'cvc', '');
  }
  switch (payment) {
    case paymentEnum.creditCardInstallment:
      dispatchAction('InstallmentInfo', 'getInstallments');
      break;
    case paymentEnum.ZingalaPayment: {
      const isNotFetchedZingalaState = renderState('ZingalaInfo', 'fetchZingalaState') === 0;
      if (isNotFetchedZingalaState) {
        dispatchAction('ZingalaInfo', 'getZingalaPay');
      }
      break;
    }
    case paymentEnum.MobilePayment:
      commitMutation('DynamicComponents', 'paymentMethod', 'mobile-payment');
      break;
    default:
      break;
  }
};

/** 判斷所選取的付款方式長出額外的區塊
 * 預設付款方式為[信用卡一次付款]，如該賣場僅能門市繳費，預設付款方式為[門市繳費]
 * @return {String} Component Name
 * */
const renderMatchComponent = () => {
  const defaultPaymentMethod = renderState('DynamicComponents', 'paymentMethod');
  const hasExtraBlockPayments = [
    'creditcard-onetime',
    'creditcard-installment',
    'creditcard-bonus',
    'mobile-payment',
    'zingala-payment',
  ];
  const isPaymentsExtraBlock = hasExtraBlockPayments.includes(defaultPaymentMethod);
  return isPaymentsExtraBlock ? defaultPaymentMethod : '';
};

/** 可用付款方式 (API 只會傳回可用付款)
 * @return: {Array} */
const payments = getState('PaymentInfo', 'payments');

/** 所選擇的付款方式 (預設選取)
 * @return: {Number} */
const payment = createDynamicComponent('PaymentInfo', 'payment', 'paymentMethod', componentLut);

/** 付款方式是否為 disable (目前用在分期付款)
 * @param: Number (0,1,2) */
const isDisabled = propEq('status', prop('disable', paymentStatusEnum));

/** [0 元訂單] 不顯示 [付款方式] */
const isShowPaymentMethod = compose(not, isZeroOrder);

/**
 * check User 當下的付款項目使其加上粗體字
 * @param {Number} paymentType
 */
const isActiveTitle = (paymentType) => {
  const thisPaymentType = renderState('PaymentInfo', 'payment');
  const isActive = paymentType === thisPaymentType;
  return isActive ? 'selected' : '';
};

/**
 * check User 當下的付款項目是否為 disabled 狀態
 * @param {Number} status
 */
const isDisabledPayment = (status) => {
  const paymentStatusIsDisabled = 2;
  const isDisabledStatus = status === paymentStatusIsDisabled;
  return isDisabledStatus ? 'lab_disable' : '';
};

/** 是否為 R 身份 */
const isRtype = () => renderState('PaymentInfo', 'isPayerReadOnly') === true;

const onlyOnePayment = () => {
  const thisPayments = renderState('PaymentInfo', 'payments');
  const mobilePayment = renderState('PaymentInfo', 'mobilePayments');
  const isOnlyOnePayment = thisPayments.length === 1 || mobilePayment.length === 1;
  return isOnlyOnePayment ? '100%' : '';
};

export default {
  name: 'payment-type-selector',
  inject: ['$validator'],
  components: {
    panelTemplate,
    DiscountBlock,
    CreditcardOnetime,
    CreditcardBonus,
    CreditcardInstallment,
    paymentMarquee,
    MobilePayment,
    ZingalaPayment,
  },
  data() {
    return {
      title: '付款選項',
    };
  },
  computed: {
    isShowPaymentMethod,
    payments,
    payment,
    isRtype,
    onlyOnePayment,
  },
  methods: {
    isDisabled,
    isDisabledPayment,
    handlePaymentData,
    renderMatchComponent,
    isActiveTitle,
  },
  mounted() {
    const userPickPaymentIndex = renderState('PaymentInfo', 'userPickPaymentIndex');
    handlePaymentView(userPickPaymentIndex);
  },
};
</script>
