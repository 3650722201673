<template>
  <div class="check-cell-content mp-box">
    <div class="check-table paylist">
      <div class="check-cell" v-for="(item, paymentIndex) in mobilePayments" :key="paymentIndex">
        <div class="checkbox">
          <input
            type="radio"
            name="mobilePayment"
            :id="item.paymentType"
            v-model="mobilePayment"
            :value="item.paymentType"
            v-validate="isNotDefault"
            @change="handlePaymentView()" />
        </div>
        <div class="text">
          <label class="radio-inline spacing right" :for="item.paymentType">
            {{ item.paymentName }}
          </label>
        </div>
      </div>
      <payment-type-selector-notice :payment-type="mobilePayment"></payment-type-selector-notice>
      <p class="error" v-if="errors.has('mobilePayment')">* 請選擇付款方式</p>
    </div>
  </div>
</template>

<script>
import { commitMutation, createDynamicComponent, getState } from '@/helpers/vuex';
import { componentLut } from '@/components/desktop/step1/payment-method/constant/payment-method.constant';
import validator from '@/validators/mobile-step2';
import PaymentTypeSelectorNotice from '@/components/mobile/payment-type-selector-notice.vue';

validator();

const mobilePayments = getState('PaymentInfo', 'mobilePayments');

/** 所選擇的行動支付付款方式 (預設選取)
 * @return: {Number} */
const mobilePayment = createDynamicComponent('PaymentInfo', 'mobilePayment', 'paymentMethod', componentLut);

/** 固定 mobile-payment component */
const handlePaymentView = () => commitMutation('DynamicComponents', 'paymentMethod', 'mobile-payment');

const isNotDefault = () => ({ is_not: 0 });

export default {
  name: 'mobile-payment',
  components: { PaymentTypeSelectorNotice },
  computed: {
    mobilePayments,
    mobilePayment,
    isNotDefault,
  },
  methods: {
    handlePaymentView,
  },
};
</script>
