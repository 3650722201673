<template>
  <div class="container">
    <header-cart>
      <template v-if="authLoading" slot="header-left">
        <div></div>
      </template>
      <template slot="header-middle">{{ getTitle }}</template>
    </header-cart>

    <main :class="mainClass">
      <form method="post" ref="cartstep2" @submit.prevent="onConfirmPayment()">
        <div>
          <!-- 步驟說明-->
          <step-state :step="2"></step-state>
        </div>
        <!-- 電子票券說明 -->
        <vtp-desc v-if="isShowVtp"></vtp-desc>
        <div v-show="!showAuthLoading">
          <!-- 付款方式 & 福利金 -->
          <payment-type-selector
            v-if="!this.firstZeroOrder"
            key="payment-type-selector-component"></payment-type-selector>
        </div>
        <div v-show="!showAuthLoading">
          <!-- 付款人資訊 -->
          <component :is="payerInfoComponent" :key="payerInfoComponent"></component>
        </div>
        <div v-show="!showAuthLoading">
          <!-- 發票選項 -->
          <invoice-method v-if="showWithNotZeroOrder" key="invoice-method-component"></invoice-method>
        </div>
        <!-- toolbar -->
        <bottom-toolbar
          v-if="showConfirmPayment"
          key="bottom-toolbar-component"
          v-show="!showAuthLoading"
          :btnDisabled="btnIsDisabled"></bottom-toolbar>
        <!-- loading -->
        <div>
          <!-- 信用卡傳輸畫面 -->
          <verify-loading
            v-show="showAuthLoading"
            :status="getLoadingStatus"
            :orderProcessClear="getLoadingOrderProcessClear"
            v-on:changeLoadingStatus="changeLoadingStatus"></verify-loading>
        </div>
      </form>
      <!-- 電子票券貼心提醒 -->
      <vtp-modal v-if="isShowVtp"></vtp-modal>
      <zingala-info-modal ref="zingalaModal" />
      <register-info-modal @get-member-registration="getMemberRegistration" @get-sms-send="getSmsSend" />
    </main>
  </div>
</template>

<script>
import { compose, prop, pipe, ifElse, always, either, __, anyPass } from 'ramda';
import { commitMutation, renderState, getState, renderGetter, getAction, getGetter } from '@/helpers/vuex';
import { setOrder } from '@/helpers/format/order-param';
import { equalProps } from '@/helpers/ramdaex';
import { completeMerchantValidation } from '@/helpers/apple-pay';
import { openModal } from '@/helpers/modal';
import { paymentEnum } from '@/constant/payment-info';
import { componentLut } from '@/components/desktop/step1/constant/step1.constant';
import { isZeroOrder } from '@/components/desktop/step1/payer-info/common/payer-info';
import { isLoginUser } from '@/helpers/is-from-app';
import { getMessage, getPayerInfoMessage } from '@/validators/common-validator';
import submitOrder from '@/api/order-process';
import orderProcessResponse from '@/mixins/cart/finished/order-process-response';
import validator from '@/validators/mobile-step2';
import store from '@/store/index';
import bottomToolbar from '@/components/mobile/bottom-toolbar.vue';
import creditcardPayer from '@/components/mobile/creditcard-payer.vue';
import headerCart from '@/components/mobile/Header/header-cart.vue';
import invoiceMethod from '@/components/mobile/step2/invoice-method.vue';
import paymentTypeSelector from '@/components/mobile/payment-type-selector.vue';
import stepState from '@/components/mobile/step-state.vue';
import StorePayer from '@/components/mobile/store-payer.vue';
import verifyLoading from '@/components/mobile/step2/verify-loading.vue';
import VtpDesc from '@/components/mobile/step2/vtp-desc.vue';
import VtpModal from '@/components/common/modals/vtp-modal.vue';
import ZingalaInfoModal from '@/components/common/modals/zingala-info-modal.vue';
import RegisterInfoModal from '@/components/mobile/step2/payment-type/register-into-modal/modal.vue';
import { memberRegistrationProcess } from '@/mixins/cart/finished/member-registration-process';

validator();

/** 根據 [目前選擇付款方式] 查表 */
const lookupComponent = pipe(getState('PaymentInfo', 'payment'), prop(__, componentLut));

/** 根據 [目前選擇行動支付付款方式] 查表 */
const lookupMobilePayment = pipe(getState('PaymentInfo', 'mobilePayment'), prop(__, componentLut));

/** 決定 [付款人資訊] 為 [信用卡付款人] 或 [其他付款人] */
/** 若為 [0 元訂單]，則使用 [信用卡付款人]，其他根據 [目前選擇付款方式] 查表 */
const payerInfoComponent = ifElse(
  isZeroOrder,
  always('creditcard-payer'),
  either(lookupComponent, lookupMobilePayment)
);

const submitOrderProcess = function () {
  /** 信用卡訂單進入 SSL loading bar, 其他顯示一般loading */
  if (this.isCreditCardAndIsNotZero) {
    this.useVerifyLoading();
  } else {
    store.dispatch('Loading/updateLoading', { isLoading: true });
  }
  submitOrder(setOrder())
    .then((res) => this.orderProcessResponseToNext(res))
    .catch((error) => this.catchResponse(error));
};

/** 判斷是否為 [信用卡] 且不為 0元訂單 */
const useVerifyLoading = function () {
  if (this.showWithNotZeroOrder) {
    this.authLoading = true;
    this.loadingStatus = 'play';
    this.title = '付款授權處理中';
  }
};

/** 改變 [刷卡授權 loading] 狀態 */
const changeLoadingStatus = function (value) {
  this.loadingStatus = value;
};

/** [刷卡授權 loading] 結束 */
const loadingSuccess = function () {
  if (this.showWithNotZeroOrder) {
    this.loadingStatus = 'stop';
    this.loadingOrderProcessClear = true;
  }
  return true;
};

/** loading 返回結帳畫面 */
const loadingGoBack = function () {
  this.authLoading = false;
  this.loadingStatus = 'reset';
  store.commit('RegisterSenaoMembership/setIsSubmitBtnDisabled', false);
};

/** 控制送出按鈕顯示 */
const showConfirmPayment = function () {
  return renderState('Step1Component', 'dynamicComponent') === 'Checkout';
};

/** 是否為 [信用卡紅利] */
const isCreditBonus = equalProps('creditCardBonus', paymentEnum);

/** 是否為 [信用卡一次] */
const isCreditOnetime = equalProps('creditCardOnetime', paymentEnum);

/** 是否為 [信用卡分期] */
const isCreditInstallment = equalProps('creditCardInstallment', paymentEnum);

/** 判斷是否為 [信用卡] 付款方式 */
const isCreditCard = compose(
  anyPass([isCreditBonus, isCreditOnetime, isCreditInstallment]),
  getState('PaymentInfo', 'payment')
);

/** 判斷是否為 [Apple Pay] 付款方式 */
const isUseApplePay = compose(equalProps('ApplePayPayment', paymentEnum), getState('PaymentInfo', 'mobilePayment'));

const components = {
  headerCart,
  stepState,
  paymentTypeSelector,
  creditcardPayer,
  StorePayer,
  invoiceMethod,
  bottomToolbar,
  verifyLoading,
  VtpModal,
  VtpDesc,
  ZingalaInfoModal,
  RegisterInfoModal,
};

const data = function () {
  return {
    title: '選擇付款方式',
    authLoading: false,
    loadingStatus: null,
    /** 一進入 step2 是否便是 0 元訂單 */
    firstZeroOrder: false,
    isShowMobilePaymentNotice: false,
  };
};

const computed = {
  submitBtnDisabled: getState('RegisterSenaoMembership', 'isSubmitBtnDisabled'),
  isCreditCard,
  isUseApplePay,
  getTitle() {
    return this.title;
  },
  showAuthLoading() {
    return this.authLoading;
  },
  getLoadingStatus() {
    return this.loadingStatus;
  },
  getLoadingOrderProcessClear() {
    return this.loadingOrderProcessClear;
  },
  /** 本次實付金額 */
  /** 使用 [運費 + 實付金額] API 計算的 amount，不使用自己計算的 realTotalAmount */
  instantAmount: getGetter('AmountDetail', 'realInstantAmount'),
  /** 0元訂單不顯示 */
  showWithNotZeroOrder() {
    return this.instantAmount > 0;
  },
  /** 是信用卡且不為零元訂單 */
  isCreditCardAndIsNotZero() {
    return this.isCreditCard && this.instantAmount > 0;
  },
  /** [電子票券] 顯示 [貼心提醒] */
  isShowVtp: getState('AmountDetail', 'isVTP'),
  payerInfoComponent,
  /** main 的底色 (訂單送出及 finished 頁為白色) */
  mainClass() {
    return this.showAuthLoading ? '' : 'offset bottom gray';
  },
  // 確定按鈕 disabled?
  btnIsDisabled() {
    return this.submitBtnDisabled;
  },
  showConfirmPayment,
  isZeroOrder,
  orderInfoMobile: getState('OrderInfo', 'mobilePhone'),
  setloadingStatus: getGetter('Loading', 'getLoadingStatus'),
  isLoginUser,
  step: getState('RegisterSenaoMembership', 'step'),
  isSelectUniform: getState('MobileCartStep2', 'isSelectUniform'),
  isUserMember: getState('RegisterSenaoMembership', 'isUserMember', true),
};

const methods = {
  submitOrderProcess,
  changeLoadingStatus,
  loadingSuccess,
  useVerifyLoading,
  loadingGoBack,
  onConfirmApplePay() {
    store.commit('RegisterSenaoMembership/setIsSubmitBtnDisabled', false);
    completeMerchantValidation();
    NCPayment.applePay.getPaymentToken((error, paymentToken) => {
      // success: { "status": 0, "errors": null }
      if (error === null) {
        commitMutation('PaymentInfo', 'applePayPaymentToken', paymentToken);
        this.submitOrderProcess();
      }
    });
  },
  onConfirmPayment() {
    // 確認付款 ; 送出訂單前的驗證
    this.$validator.validateAll().then(() => this.checkOutValidate());
  },
  checkOutValidate() {
    // 檢查 vee-validator 抓出的錯誤
    if (this.errors.items.length) {
      const focusInputName = this.errors.items[0].field;
      const errorItemName = this.errors.items[0];
      const allMessage = getMessage(errorItemName);
      // focus 該 input element
      const thisElName = this.$root.$el.querySelector(`[name=${focusInputName}]`);
      // 結帳金額 > 0 全部表單需驗證
      if (this.instantAmount > 0) {
        alert(allMessage);
        if (thisElName !== null) {
          thisElName.focus();
        }
        return;
      }
      // 零元訂單只需驗證付款人表單
      const payerInfoMsg = getPayerInfoMessage(errorItemName);
      alert(payerInfoMsg);
      if (thisElName !== null) {
        thisElName.focus();
      }
      return;
    }
    // 如有週期購商品，信用卡到期日與結帳 API 回傳的 flag: validThru，如果小於則 alert 並滑到輸入卡號那重填
    // 付款方式需為信用卡類
    // 判斷信用卡過期
    // 如折扣金將訂單折為0元，不應檢查信用卡片是否過期
    if (this.instantAmount > 0) {
      const isValidateExpired = renderGetter('CardInfo', 'isValidateExpired');
      if (this.isCreditCard && isValidateExpired) {
        // 判斷是否為週期購商品及驗證卡片有效期限
        const isCycle = renderState('CardInfo', 'isCycle') === 1;
        const cardExpiredErrorMsg = isCycle
          ? '請您確認提供之信用卡有效年月，是否大於您此次購買週期購預約的年月效期。'
          : '請您確認提供之信用卡有效年月。';
        alert(cardExpiredErrorMsg);
        // 滑到輸入卡號那重填
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        return;
      }
    }
    if (this.isUserMember) {
      openModal('GoLoginModal');
      return;
    }
    if (!this.isLoginUser) {
      openModal('RegisterModal');
      return;
    }
    if (this.submitBtnDisabled) {
      return;
    }
    store.commit('RegisterSenaoMembership/setIsSubmitBtnDisabled', true);
    this.onConfirmPaymentAndOrderProcess();
  },
  onConfirmPaymentAndOrderProcess() {
    if (window.ApplePaySession && this.isUseApplePay) {
      this.onConfirmApplePay();
      return;
    }
    this.submitOrderProcess();
  },
};

const mounted = function () {
  /** show loading */
  store
    .dispatch('Loading/updateLoading', { isLoading: true })
    .then(() => {
      /** 更新 step2/updateCityTown */
      store.dispatch('MobileCartStep2/updateCityTownStatus', true);
      const realTotalAmount = renderGetter('AmountDetail', 'realTotalAmount');
      if (realTotalAmount === 0) {
        /** 一進入 step2 便是 0 元訂單 */
        this.firstZeroOrder = true;
      }
      if (!this.isSelectUniform) {
        getAction('AmountDetail', 'getVCash')();
      }
      getAction('CityTownTaiwan', 'getAllCityTownTaiwan')();
    })
    /** hide loading */
    .then(() => {
      store.dispatch('Loading/updateLoading', { isLoading: true });
    });
};

export default {
  name: 'default-view',
  components,
  data,
  mixins: [orderProcessResponse, memberRegistrationProcess],
  computed,
  methods,
  mounted,
};
</script>
