<template>
  <div class="container">
    <header-cart>
      <template slot="header-middle">銀行分期列表</template>
      <template slot="header-left">
        <a class="btn-link" href="javascript:void(0);" @click="hideBankList">
          <i class="icon icon-close"></i>
        </a>
      </template>
    </header-cart>

    <!-- 銀行列表 -->
    <main class="fixed top white">
      <div class="bank-list">
        <span v-for="(item, index) in getBankList" :key="index">
          {{ item.bankName }}
        </span>
      </div>
    </main>
  </div>
</template>

<script>
import headerCart from '@/components/mobile/Header/header-cart.vue';

const components = {
  headerCart,
};

const computed = {
  /** 取得銀行分期列表 */
  getBankList() {
    return this.$store.getters['MobileCartStep2/getpageData'] || [];
  },
};

const methods = {
  /** 關閉銀行分期列表返回表單頁 */
  hideBankList() {
    return this.$store.dispatch('MobileCartStep2/changePage', {
      page: 'form',
      data: [],
    });
  },
};

export default {
  name: 'step2-installment-list',
  components,
  computed,
  methods,
};
</script>

<style scoped>
header.fixed {
  z-index: 3100 !important;
}
.fixed.top.white {
  top: 0;
  left: 0;
  right: 0;
}
</style>
