<template>
  <panel-template :title="title">
    <template slot="panel-body">
      <!-- 付款人姓名 -->
      <inputRow
        :title="'中文姓名'"
        :notice="true"
        :placeholder="'請填寫中文全名'"
        :subTitle="instantAmount !== 0 ? '(請填信用卡持卡人姓名)' : ''"
        :inputName="'payerName'"
        :hookType="'store'"
        :hookStore="[`${moduleName}`, 'name']"
        :maxlength="6"
        :validateRule="payerNameValidateRule"
        :module="moduleName"
        :isRtype="isRtype"
        :is-show-import-order-info="true"></inputRow>

      <!-- 手機號碼 -->
      <inputRow
        :title="'手機'"
        :notice="true"
        :placeholder="'請填寫手機號碼'"
        :subTitle="instantAmount !== 0 ? '(請填信用卡持卡人手機)' : ''"
        :inputType="'tel'"
        :inputName="'payerPhone'"
        :hookType="'store'"
        :hookStore="[`${moduleName}`, 'mobilePhone']"
        :maxlength="10"
        :validateRule="'required|mobile-phone'"
        :module="moduleName"
        :isRtype="isRtype"></inputRow>

      <!-- 市話 -->
      <telPhoneRow
        :title="'市話'"
        :notice="false"
        :subTitle="'(範例: 02-12340000 #5678)'"
        :payerAreaName="'payerArea'"
        :payerNumberName="'payerNumber'"
        :payerExtName="'payerExt'"
        :hookType="'store'"
        :hookStoreWithArea="[`${moduleName}`, 'localPhoneArea']"
        :hookStoreWithNumber="[`${moduleName}`, 'localPhone']"
        :hookStoreWithExt="[`${moduleName}`, 'localPhoneExt']"
        :module="moduleName"
        :isRtype="isRtype"></telPhoneRow>

      <!-- email -->
      <inputRow
        :title="'Email'"
        :notice="true"
        :placeholder="'請填寫Email'"
        :inputType="'email'"
        :inputName="'payerEmail'"
        :hookType="'store'"
        :hookStore="[`${moduleName}`, 'email']"
        :maxlength="200"
        :validateRule="'required|custom-email'"
        :module="moduleName"
        :isRtype="isRtype"></inputRow>

      <!-- 收件地址 -->
      <address-row
        :idKey="'payer'"
        :key="moduleName"
        :module="moduleName"
        :states="stateNames"
        :island="true"
        :isRtype="isRtype"></address-row>
      <!-- 記住信用卡資訊下次使用 -->
      <div class="fieldset" v-if="isShowCardKeep">
        <label>
          <input
            type="checkbox"
            name="agreeCardKeep"
            v-model="cardKeep"
            :disabled="isRtype"
            v-validate="{ required: isCycleCart }" />
          {{ message }}
        </label>
        <!-- 注意事項-->
        <p class="divider notice fn-sm" v-if="isShowCardKeep">
          *
          為確保網路交易安全，您了解本公司得就本件交易資料向發卡銀行及持卡人照會是否屬實，核對無誤後付款才算完成。提醒您，如有冒用他人信用卡或其他個人資料而交易者，經查獲必移送法辦。
        </p>
      </div>
    </template>
  </panel-template>
</template>

<script>
import { createComputed, renderState, getGetter } from '@/helpers/vuex';
import addressRow from '@/components/mobile/step2/address-row.vue';
import inputRow from '@/components/mobile/step2/input-row.vue';
import panelTemplate from '@/components/mobile/elements/panel-template.vue';
import telPhoneRow from '@/components/mobile/step2/tel-phone-row.vue';
import { isShowCardKeepMsg, isZeroOrder } from '@/components/desktop/step1/payer-info/common/payer-info';
// 檢查車裡是否有週期購商品 ; 如有，需檢查是否勾選記住信用卡資訊
const isCycleCart = () => renderState('CardInfo', 'isCycle') === 1;

// 是 R 身份者 disable 付款人表單
const isRtype = () => renderState('PaymentInfo', 'isPayerReadOnly');

// 付款人姓名中文檢查
const payerNameValidateRule = () => (isZeroOrder() ? 'required' : 'required|chinese-only');

const components = {
  panelTemplate,
  inputRow,
  telPhoneRow,
  addressRow,
};

const data = function () {
  return {
    title: '付款人資訊',
    /** state 的 module 名稱 */
    moduleName: 'CardInfo',
    /** address-row 所綁定的 state */
    stateNames: ['addressCityId', 'addressCity', 'addressTownId', 'addressTown', 'addressZip', 'address'],
  };
};

export default {
  name: 'payer-info',
  inject: ['$validator'],
  components,
  data,
  computed: {
    cardKeep: createComputed('CardInfo', 'cardKeep'),
    instantAmount: getGetter('AmountDetail', 'realInstantAmount'),
    payerNameValidateRule,
    isRtype,
    isCycleCart,
    message() {
      return this.isCycleCart ? '記住信用卡資訊，以供週期購續訂、下次訂購時使用。' : '記住信用卡資訊下次使用';
    },
    isShowCardKeep() {
      return isShowCardKeepMsg(this.module);
    },
  },
};
</script>
