<template>
  <div class="check-cell-content">
    <div class="panel-body">
      <div class="fieldset">
        <input
          type="text"
          placeholder="請輸入/大寫英數字7碼"
          name="mobile_device_code"
          maxlength="8"
          size="8"
          autocomplete="off"
          v-model="value"
          v-validate.initial="'required|invoice-mobile-device'"
          key="mobile_device_code" />
        <p v-if="errors.has('mobile_device_code')" class="error">* {{ errors.first('mobile_device_code') }}</p>
      </div>
      <p class="divider_2">
        <label class="notice">
          <input type="checkbox" v-model="isSaveDeviceCode" />
          我同意紀錄本次手機條碼為常用載具。
        </label>
      </p>
    </div>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';

export default {
  name: 'invoice-phone',
  inject: ['$validator'],
  computed: {
    value: createComputed('InvoiceInfo', 'phoneDeviceCode'),
    isSaveDeviceCode: createComputed('InvoiceInfo', 'isSaveDeviceCode'),
  },
};
</script>
