<template>
  <div v-if="isShowDiscountItem">
    <div class="text-bar">
      <label>各折抵項目 依照頁面顯示上→下 依序進行折抵</label>
    </div>
    <div class="fieldset" v-for="(item, index) in getDiscountList" :key="index">
      <label>打勾使用{{ item.mobileDiscountName }}折抵</label>
      <div class="check-table">
        <div class="check-cell">
          <div class="checkbox">
            <input
              type="checkbox"
              :id="`check${item.discountName}`"
              :name="`${item.discountName}`"
              :checked="item.isUsed"
              @change.prevent="updateDiscountListFn(item)" />
          </div>
          <label class="text checkbox-label" :for="`check${item.discountName}`">
            <div class="w-fix-mini fn-md" :class="{ 'w-fix-mini-hami': isNotVCash(item.discountName) }">
              {{ item.mobileDiscountName }}
            </div>
            <span class="text-muted">
              可用{{ item.mobileDiscountTxt }}
              <span>{{ item.total | dollar }}</span>
              元
            </span>
            <b class="price spacing left">-{{ item.deduct | dollar }}</b>
            <!-- 各折扣使用說明 -->
            <div class="clearfix spacing top mini text-muted" v-if="item.note">
              {{ item.note }}
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGetter, dispatchAction } from '@/helpers/vuex';
import { isShowDiscountItem } from '@/helpers/payer-info';
import { dollar } from '@/helpers/format/dollar';

const getDiscountList = getGetter('AmountDetail', 'getterDiscountList');

const isNotVCash = function (item) {
  return item !== 'VCash';
};

export default {
  name: 'discount-block',
  filters: {
    dollar,
  },
  computed: {
    isShowDiscountItem,
    getDiscountList,
  },
  methods: {
    isNotVCash,
    updateDiscountListFn(item) {
      return dispatchAction('AmountDetail', 'calculateDiscountListFn', item);
    },
  },
};
</script>
