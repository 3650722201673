<template>
  <div class="fieldset">
    <div class="select-group half">
      <!-- 縣市 -->
      <city-town-select
        name="city"
        :id="`${idKey}-city`"
        :source="cities"
        :disabled="isRtype"
        v-model="cityIndex"
        @zip-change="onCityChange"
        v-validate="isNotPleaseSelect"></city-town-select>
      <!-- 鄉鎮地區 -->
      <city-town-select
        name="town"
        :disabled="isRtype"
        :id="`${idKey}-town`"
        :source="towns"
        v-model="townIndex"
        v-validate="isNotPleaseSelect"></city-town-select>
      <!-- 郵遞區號 / for orderProcess API POST -->
      <input class="hide" type="hidden" readonly="readonly" :id="`${idKey}-zip`" :value="zip" />
    </div>
    <input
      v-if="isNotMasked"
      name="address"
      class="input-lg"
      type="text"
      maxlength="50"
      autocomplete="off"
      placeholder="請填寫完整地址"
      :id="`${idKey}-address`"
      :disabled="isRtype"
      v-model.trim="address"
      v-validate="addressRules" />
    <input v-else v-model.trim="maskedAddress" type="text" class="input-lg" @focus="isNotMasked = true" />
    <p v-if="errors.has('city')" class="error">* {{ errors.first('city') }}</p>
    <p v-if="errors.has('town')" class="error">* {{ errors.first('town') }}</p>
    <p v-if="errors.has('address')" class="error">* {{ errors.first('address') }}</p>
  </div>
</template>

<script>
import { propEq } from 'ramda';
import { baseCityTown, regexpAddress } from '@/helpers/city-town';
import { commitMutation, commitMutations, renderState, renderGetter } from '@/helpers/vuex';
import { findIndexDefault } from '@/helpers/array';
import { useMask } from '@/composables/mask';
import { defaultCity, defaultTown } from '@/components/desktop/step1/common/city-town/city-town.default';
import { index } from '@/components/desktop/step1/common/constant/common-city-town-zip.constant';
import CityTownSelect from '@/components/desktop/step1/common/city-town/city-town-select.vue';

/** 綁定 city 的 index */
const cityIndex = {
  get() {
    return this.cityIndexData;
  },
  set(value) {
    this.cityIndexData = value;
    commitMutations(this.module, {
      [this.states[index.addressCityId]]: this.cities[value].id,
      [this.states[index.addressCity]]: this.cities[value].name,
    });
  },
};

/** 綁定 town 的 index */
const townIndex = {
  get() {
    return this.townIndexData;
  },
  set(value) {
    this.townIndexData = value;
    commitMutations(this.module, {
      [this.states[index.addressTownId]]: this.towns[value].id,
      [this.states[index.addressTown]]: this.towns[value].name,
    });
  },
};

/** 產生是否外島的 filter function */
const isIslandInCities = (island) => {
  if (island) return () => true;
  return (x) => !x.districts.every((y) => y.isIsland === !island);
};

/** 顯示第一層 select 所需的 array */
const cities = function () {
  return [defaultCity /** 請選擇 */, ...this.allCityTownTaiwan.cities.filter(isIslandInCities(this.island))];
};

/** 產生是否外島的 filter function */
const isIslandInTowns = (island) => {
  if (island) return () => true;
  return (x) => x.isIsland === island;
};

/** 顯示第二層 select 所需的 array */
const towns = function () {
  return [defaultTown /** 請選擇 */, ...this.cities[this.cityIndex].districts.filter(isIslandInTowns(this.island))];
};

/** 顯示第三層所顯示的'郵遞區號' */
const zip = function () {
  return this.towns[this.townIndex].zip;
};

/**
 * 收件地址驗證規則
 *  必填
 *  最少 6 個字，最多 60 個字
 *  不可包含 `郵遞區號` 或 `郵遞 .... 區號`
 * */
const addressRules = () => 'required|address-length|postal-box';

/** [收件地址] */
const address = {
  get() {
    return renderState(this.module, 'address');
  },
  set(value) {
    commitMutation(this.module, this.states[index.address], regexpAddress(value));
  },
};

/** [addressCityId] */
const addressCityId = function () {
  return renderState(this.module, 'addressCityId');
};

/** [addressTownId] */
const addressTownId = function () {
  return renderState(this.module, 'addressTownId');
};

/** 當 [addressCityId] 改變時，找出城市的 select index */
const onAddressCityId = function (value) {
  if (value === '') {
    return '';
  }
  return (this.cityIndexData = findIndexDefault(propEq('id', value), 0, this.cities));
};

/** 當 [addressTownId] 改變時，找出鄉鎮市的 select index */
const onAddressTownId = function (value) {
  if (value === '') {
    return '';
  }
  return (this.townIndexData = findIndexDefault(propEq('id', value), 0, this.towns));
};

/** 當 [zip] 改變時，直接 mutate state */
const onZip = function (value) {
  commitMutation(this.module, this.states[index.addressZip], value);
};

/** 當城市改變時，馬上將鄉鎮區 reset 從頭開始，避免舊資料殘留 */
const onCityChange = function () {
  this.townIndex = 0;
};

/** 預設 city town */
const defaultCityTown = function () {
  const myCityName = this.cityName;
  const myTownName = this.townName;
  this.cities.forEach((x, i) => {
    if (x.name === myCityName) {
      this.cityIndex = i;
      x.districts.forEach((x2, i2) => {
        if (x2.name === myTownName) {
          this.townIndex = i2 + 1;
        }
      });
    }
  });
};

export default {
  name: 'address-row',
  inject: ['$validator'],
  components: {
    CityTownSelect,
  },
  props: {
    /** 與 Vuex 綁定的 module 名稱 */
    module: {
      type: String,
      default: '',
    },
    /** 與 Vuex 綁定的 state 名稱 */
    states: {
      type: Array,
      default: () => [],
    },
    /** 是否包含外島 */
    island: {
      type: Boolean,
      default: false,
    },
    /** 由 api 回傳是否為 R 身份 */
    isRtype: {
      type: Boolean,
      default: false,
    },
    /** 成立訂單時直接抓取 dom 物件拿參數使用 */
    idKey: {
      type: String,
      default: 'default',
    },
  },
  data: () => ({
    /** select 改變時動態改變的 index，為 v-model */
    cityIndexData: 0,
    townIndexData: 0,
  }),
  computed: {
    allCityTownTaiwan() {
      return baseCityTown.data;
    },
    /** 所有 API 資料 */
    cityIndex,
    townIndex,
    cities,
    towns,
    zip,
    addressRules,
    address,
    /** 鄉鎮市不能是 `請選擇` */
    isNotPleaseSelect: () => ({ is_not: 0 }),
    addressCityId,
    addressTownId,
    cityName() {
      return renderState(this.module, 'addressCity');
    },
    townName() {
      return renderState(this.module, 'addressTown');
    },
    canUpdateCityTownStatus() {
      return renderGetter('MobileCartStep2', 'getUpdateCityTownStatus');
    },
  },
  watch: {
    addressCityId: onAddressCityId,
    addressTownId: onAddressTownId,
    zip: onZip,
    /** 若 api 更新 store 內的 city town 時重整 city, town select 欄位 */
    canUpdateCityTownStatus() {
      if (this.canUpdateCityTownStatus === true) {
        this.defaultCityTown();
        /** 更新 step2/updateCityTown */
        this.$store.dispatch('MobileCartStep2/updateCityTownStatus', false);
      }
    },
  },
  methods: {
    onCityChange,
    defaultCityTown,
  },
  mounted() {
    this.defaultCityTown();
    this.$validator.validate('*');
  },
  created() {
    this.setIsNotMaskedByValue(this.address);
  },
  setup(props) {
    const { maskedAddress, isNotMasked, setIsNotMaskedByValue } = useMask(props);
    return { maskedAddress, isNotMasked, setIsNotMaskedByValue };
  },
};
</script>
