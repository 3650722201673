<template>
  <div class="check-cell-content btl" v-if="showList">
    <div class="check-table pay-installment sticky">
      <div v-for="(item, index) in getInstallmentData" :key="index" class="check-cell">
        <div class="checkbox">
          <input
            type="radio"
            name="installmentType"
            v-model="installmentTypeValue"
            :value="index"
            :id="getInstallmentId(item)"
            v-validate="'required'" />
        </div>
        <div class="text">
          <label :for="getInstallmentId(item)">
            <b class="price">{{ item.period }}</b>
            &nbsp;期&nbsp;
            <b class="price">{{ item.rate | percent }}</b>
            &nbsp;利率，每期約&nbsp;
            <b class="price">{{ item.money | dollar }}</b>
            <a class="underline inverted fn-sm spacing" href="javascript:void(0);" @click="showBankList(index)">
              ({{ item.banks.length }}家銀行)
            </a>
          </label>
        </div>
      </div>
      <p class="error" v-if="errors.has('installmentType')">* 請選擇分期付款方式</p>
    </div>
    <creditcard-info :is-rtype="isRtype"></creditcard-info>
    <div class="fieldset">
      <ul class="has-type-disc text-muted">
        <li>除不盡餘數於第一期收取。「系統自動帶出商品共同可接受分期數」</li>
        <li>
          所有價格一律含稅，接受信用卡
          <i class="icon icon-visa"></i>
          <i class="icon icon-master-card"></i>
          <i class="icon icon-jcb"></i>
        </li>
        <li>實際分期金額請依各發卡銀行規定為準</li>
      </ul>
      <p class="divider notice fn-sm">
        * 貼心提醒：當使用「信用卡分期」方式付款，若您單筆訂單中有多品項商品，後續辦理退貨僅能受理整筆訂單退貨。
      </p>
    </div>
  </div>
</template>

<script>
import { dollar, percent } from '@/helpers/format/dollar';
import { renderState, createComputed } from '@/helpers/vuex';
import CreditcardInfo from '@/components/mobile/step2/payment-type/common/creditcard-info.vue';

const data = function () {
  return {
    value: '',
  };
};

const computed = {
  /** 分期資訊 */
  getInstallmentData() {
    return renderState('InstallmentInfo', 'installments');
  },
  /** 顯示分期列表 */
  showList() {
    return this.getInstallmentData.length > 0;
  },
  installmentTypeValue: createComputed('InstallmentInfo', 'installmentIndex'),
};

const filters = {
  dollar,
  percent,
  // 加上千分位符號
  commaFormat(value) {
    value += '';
    return value.replace(/\d{1,3}(?=(\d{3})+$)/g, (str) => `${str},`);
  },
};

const methods = {
  getInstallmentId(item) {
    return `installment_${item.period}_${item.rate}`;
  },
  showBankList(value) {
    return this.$store.dispatch('MobileCartStep2/changePage', {
      page: 'bank',
      data: this.getInstallmentData[value].banks,
    });
  },
};

const mounted = function () {
  this.$validator.validate('*');
};

export default {
  name: 'creditcard-installment',
  inject: ['$validator'],
  components: {
    CreditcardInfo,
  },
  props: {
    isRtype: Boolean,
  },
  data,
  computed,
  filters,
  methods,
  mounted,
};
</script>
