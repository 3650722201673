<template>
  <div class="progress-bar" ref="progress-bar" :style="loadingBarWidth"></div>
</template>

<script>
const data = function () {
  return {
    /** @var {Number} this.progress 百分比 */
    progress: 0,
    /** @var {Number} this.progressInterval 存放 setInterval id number */
    progressInterval: 0,
    /** @var {Number} this.intervalTimer 更新速率 (毫秒) */
    intervalTimer: 200,
    /** @var {String[]} this.statusList 可用狀態列表 */
    statusList: ['play', 'pause', 'stop', 'reset'],
  };
};

const props = {
  status: {
    type: String,
    default: null,
  },
};

const computed = {
  /**
   * 取得狀態
   * @retutn {String} status 狀態
   */
  getStatus() {
    return this.statusList.indexOf(this.status) > -1 ? this.status : this.statusList[0];
  },

  /**
   * loading bar 顯示百分比
   * @return {String} progress 目前百分比進度
   */
  loadingProgress() {
    // 最大100
    if (this.progress > 100) {
      this.progress = 100;
    }

    // 最小 0, 不顯示
    if (this.progress <= 0) {
      return '';
    }

    return `${this.progress}%`;
  },

  /**
   * loading bar 進度寬度 (binding inline style)
   * @return {Object} inline style json
   */
  loadingBarWidth() {
    return { width: this.loadingProgress };
  },
};

const methods = {
  /**
   * clear 清除 progressInterval
   */
  clear() {
    clearInterval(this.progressInterval);
    this.progressInterval = 0;
  },

  /**
   * 改變速率
   * @param {number} s sec
   */
  changeTimer(s = 200) {
    clearInterval(this.progressInterval);
    this.progressInterval = setInterval(() => this.addProgress(), s);
  },

  /**
   * 增加 progress, 並回傳進度
   */
  addProgress() {
    /** 增加進度百分比 */
    if (this.progress < 100) {
      this.progress++;
      // this.progress+=5;

      /** 百分比更動時發送進度 */
      this.postEmit();
    }

    /** 進度大於等於 100 時停止動作 */
    if (this.progress >= 100) {
      this.clear();
      this.status = 'stop';
    }
  },

  /**
   * play 開始
   */
  play() {
    if (this.progress === 100) {
      this.progress = 0;
    }
    this.clear();

    this.progressInterval = setInterval(() => this.addProgress(), this.intervalTimer);
  },

  /**
   * pause 暫停
   */
  pause() {
    this.clear();
  },

  /**
   * stop 停止, 並且跑至 100%
   */
  stop() {
    this.progress = 100;
    this.changeTimer(30);
  },

  /**
   * reset 歸0, 並停止動作
   */
  reset() {
    this.progress = 0;
    this.clear();
  },

  /**
   * send $emit 回傳上層目前狀態及百分比進度
   */
  postEmit() {
    this.$emit('updateProgress', {
      status: this.status,
      progress: this.progress,
    });
  },
};

const watch = {
  status(value) {
    if (typeof this[value] === 'function') {
      this[value]();
    } else {
      this.stop();
    }

    /** 狀態更新時發送進度 */
    this.postEmit();
  },
};

export default {
  name: 'loading-progress',
  data,
  props,
  computed,
  methods,
  watch,
  beforeDestroy() {
    clearInterval(this.progressInterval);
  },
};
</script>
