<template>
  <div class="fieldset">
    <label>
      <slot name="label">
        <b class="notice">*</b>
        地址
      </slot>
    </label>
    <div class="select-group half">
      <!-- 縣市 -->
      <city-town-select v-model="city" :name="cityName" :source="citySource" v-validate="required"></city-town-select>
      <!-- 鄉鎮區 -->
      <city-town-select
        v-model="townIndex"
        :name="townName"
        :source="townSource"
        v-validate="required"></city-town-select>
    </div>
    <input
      :name="addressName"
      type="text"
      placeholder="請填寫完整地址"
      maxlength="50"
      autocomplete="off"
      v-model="address"
      v-validate="'required|address-length|postal-box'" />
    <p v-if="errors.has(cityName)" class="error">* {{ errors.first(cityName) }}</p>
    <p v-if="errors.has(townName)" class="error">* {{ errors.first(townName) }}</p>
    <p v-if="errors.has(addressName)" class="error">* {{ errors.first(addressName) }}</p>
  </div>
</template>

<script>
import { cities } from '@/helpers/city-town';
import cityTownSelect from '@/components/desktop/step1/common/city-town/city-town-select.vue';

const cityTownFirstRow = {
  id: -1,
  name: '請選擇 ...',
  districts: [
    {
      id: -1,
      name: '請選擇 ...',
    },
  ],
};

const districtFirstRow = {
  id: 0,
  name: '請選擇 ...',
};

const props = {
  recipient: Object,
  cityName: {
    type: String,
    default: 'city',
  },
  townName: {
    type: String,
    default: 'town',
  },
  addressName: {
    type: String,
    default: 'address',
  },
};

const data = function () {
  return {
    citySource: [],
    townSource: [],
    cityIndex: -1,
    townIndex: -1,
    addressee: {
      zip: '',
      city: '',
      cityName: '',
      town: '',
      townName: '',
      address: '',
    },
  };
};

const components = {
  cityTownSelect,
};

const methods = {
  getValidatorError(column) {
    return this.errors.items.filter((error) => error.field === column);
  },
  setSelectorDefault() {
    // 塞預設值
    const cityId = this.getRecipient.city === '' ? -1 : this.getRecipient.city;
    const townId = this.getRecipient.town === '' ? -1 : this.getRecipient.town;

    // 塞入先前已經選好的 index
    const cityIndex = this.citySource.map((city) => city.id).indexOf(cityId);

    // 若 indexOf === -1 代表無此資料, 無資料時預設為 0 `請選擇 ...`
    this.cityIndex = cityIndex === -1 ? 0 : cityIndex;

    // 取得對應 index 的 town data 來抓 index
    const townIndex =
      this.citySource[this.cityIndex].districts
        .filter((x) => x.isIsland === false)
        .map((town) => town.id)
        .indexOf(townId) + 1;

    // 若 indexOf === -1 代表無此資料, 無資料時預設為 0 `請選擇 ...`
    this.townIndex = townIndex === -1 ? 0 : townIndex;

    this.addressee.address = this.getRecipient.address;
  },
};

const watch = {
  recipient() {
    this.setSelectorDefault();
  },
  'recipient.address': function () {
    this.addressee.address = this.recipient.address;
  },
  'recipient.cityName': function () {
    this.addressee.cityName = this.recipient.cityName;
  },
  'recipient.townName': function () {
    this.addressee.townName = this.recipient.townName;
    this.setSelectorDefault();
  },
  cityIndex() {
    const selected = this.citySource[this.cityIndex];

    // 根據選擇到的城市，把鄉鎮資料塞入 town data source。
    this.townSource = [districtFirstRow, ...selected.districts.filter((x) => x.isIsland === false)];

    // 資料為 `請選擇` 時，不寫入 object
    if (selected.id === -1) {
      return;
    }
    this.addressee.city = selected.id;
    this.addressee.cityName = selected.name;
  },
  townIndex() {
    const selected = this.townSource[this.townIndex];

    // 資料為 `請選擇` 時，不寫入 object
    if (selected.id === -1) {
      return;
    }

    this.addressee.town = selected.id;
    this.addressee.townName = selected.name;
    this.addressee.zip = selected.zip;
  },
  getRecipient() {
    this.addressee.address = this.getRecipient.address;
  },
  addressee: {
    handler() {
      this.$emit('change', this.addressee);
    },
    deep: true,
  },
};

const computed = {
  getRecipient() {
    return this.recipient;
  },
  required() {
    return {
      is_not: 0,
    };
  },
  city: {
    get() {
      return this.cityIndex;
    },
    set(value) {
      this.townIndex = 0;
      this.cityIndex = value;
    },
  },
  town: {
    get() {
      return this.townIndex;
    },
    set(value) {
      this.townIndex = value;
    },
  },
  address: {
    get() {
      return this.addressee.address;
    },
    set(value) {
      this.addressee.address = value;
    },
  },
};

const mounted = function () {
  this.citySource = [cityTownFirstRow, ...cities(false)];
  this.setSelectorDefault();

  // 帶入地址預設值
  this.addressee.address = this.getRecipient.address;
};

export default {
  name: 'address-selector',
  inject: ['$validator'],
  props,
  components,
  data,
  methods,
  computed,
  mounted,
  watch,
};
</script>
