<template>
  <div v-if="!isRtype">
    <div class="fieldset">
      <a class="underline link_b" href="javascript:void(0);" @click="onChangeCard"> 清除信用卡 </a>
      <!-- 預設卡號 -->
      <input
        type="text"
        v-if="isShowRecord"
        class="form_ro"
        placeholder="0000 - 0000 - 0000 - 0000"
        readonly
        :value="quickCardNo" />
      <!-- 輸入卡號 -->
      <input
        type="tel"
        v-else
        placeholder="請填寫信用卡卡號"
        name="card-no"
        maxlength="16"
        v-model="getCard"
        v-validate.immediate="{ required: isCvcRequired, 'cardno-length': true }"
        autocomplete="off" />
      <p v-if="errors.has('card-no') && !isShowRecord" class="error">* {{ errors.first('card-no') }}</p>
    </div>
    <div class="fieldset">
      <div class="select-group half" v-if="isShowRecord">
        <input type="text" class="form_ro" readonly value="**月/****年" />
      </div>
      <div class="select-group half" v-else>
        <select v-model="month" name="card-month" v-validate.immediate="{ required: isCvcRequired }">
          <option value="">有效月</option>
          <option v-for="item in months" :value="item" :key="item">{{ item }}月</option>
        </select>
        <select v-model="year" name="card-year" v-validate.immediate="{ required: isCvcRequired }">
          <option value="">有效年</option>
          <option v-for="item in years" :value="item" :key="item">{{ item }}年</option>
        </select>
        <p v-if="errors.has('card-month')" class="error">* {{ errors.first('card-month') }}</p>
        <p v-if="errors.has('card-year')" class="error">* {{ errors.first('card-year') }}</p>
      </div>
    </div>
    <div class="fieldset">
      <input
        type="tel"
        placeholder="請填寫卡片背面三至四碼"
        v-model="cvcValue"
        maxlength="4"
        max="9999"
        name="card-code"
        autocomplete="off"
        v-validate="{ required: isCvcRequired, 'cvc-length': true }" />
      <p v-if="errors.has('card-code')" class="error">* {{ errors.first('card-code') }}</p>
    </div>
  </div>
</template>

<script>
import { compose } from 'ramda';
import fetchCardInfo from '@/api/step1/card-info';
import {
  commitMutation,
  createComputed,
  getState,
  dispatchAction,
  renderState,
  commitMutations,
  renderGetter,
} from '@/helpers/vuex';
import store from '@/store/index';
import { genYears, genMonths } from '@/helpers/date';
import { getInfoResponse } from '@/helpers/payer-info';
import { substr } from '@/helpers/string';
import { getCardType } from '@/components/desktop/step1/payment-method/common/child/child/constant/creditcard-demo.constant';
import { isLoginUser } from '@/helpers/is-from-app';

/** 產生 [快速刷卡] 所需要的卡號 */
const makeQuickCardNo = (start, end = 4) => compose(substr(start, end), getState('CardInfo', 'quickCardNo'));

/** 按下 [更換卡片] */
const onChangeCard = function () {
  commitMutation('PaymentInfo', 'isShowRecord', false);
  commitMutations('CardInfo', {
    cardNo: '',
    cvc: '',
    cardType: '',
    quickCardNo: '',
    quickExpiredMonth: '',
    quickExpiredYear: '',
    quickIssuer: 0,
  });
  dispatchAction('PaymentInfo', 'AmexUpdatePaymentType');
  this.getCard = '';
  this.month = '';
  this.year = '';
  this.cvcValue = '';
  return this.$nextTick(() => {
    this.$validator.validate('*');
  });
};

const mounted = function () {
  if (!this.isLoginUser) {
    commitMutation('PaymentInfo', 'isShowRecord', false);
    return;
  }
  if (this.isRtype) {
    commitMutation('PaymentInfo', 'isShowRecord', true);
    // 直接呼叫快速刷卡 API
    dispatchAction('CardInfo', 'getQuickSettle');
    return;
  }
  // 當無存過信用卡資訊時，才能呼叫 /getInfo API
  const fetchCardInfoResCode = store.state.CardInfo.code;
  const notFetchedCardInfo = '';
  if (fetchCardInfoResCode === notFetchedCardInfo) {
    fetchCardInfo().then(getInfoResponse);
  }
  const checkQuickCardNo = store.state.CardInfo.quickCardNo;
  // case: 未 reload 回到 step2 或無存過卡號的 User
  if (checkQuickCardNo === '') {
    commitMutation('PaymentInfo', 'isShowRecord', false);
  }
};

const data = function () {
  return {
    title: '信用卡資訊',
    cardEncode: true,
    // 信用卡 月份、年份
    months: genMonths,
    years: genYears,
    // 末三碼
    cardCode: '',
  };
};

const methods = {
  onChangeCard,
};

const computed = {
  /** 更換卡片 */
  isShowRecord: createComputed('PaymentInfo', 'isShowRecord'),
  getCard: createComputed('CardInfo', 'cardNo'),
  /** 有效期限 (月) */
  month: createComputed('CardInfo', 'cardExpiredMonth'),
  /** 有效期限 (年) */
  year: createComputed('CardInfo', 'cardExpiredYear'),
  /** cvc */
  cvcValue: createComputed('CardInfo', 'cvc'),
  cardType() {
    return getCardType(this.getCard);
  },
  // 快速刷卡格式 (區分一般卡或美國運通卡)
  quickCardNo() {
    const type = getCardType(renderState('CardInfo', 'quickCardNo')) || '';
    return type === 'Amex'
      ? `${makeQuickCardNo(0)()} - ****** - *${makeQuickCardNo(11)()}`
      : `${makeQuickCardNo(0)()} - ${makeQuickCardNo(4)()} - **** - ${makeQuickCardNo(12)()}`;
  },
  isAmex() {
    return this.cardType === 'Amex';
  },
  isLoginUser,
  isCvcRequired() {
    return renderGetter('AmountDetail', 'realInstantAmount') > 0;
  },
};

export default {
  name: 'creditcard-info',
  inject: ['$validator'],
  props: {
    isRtype: Boolean,
  },
  data,
  methods,
  computed,
  mounted,
  watch: {
    cardType() {
      new Promise((resolve) => resolve())
        // 更新信用卡卡別
        .then(() => commitMutation('CardInfo', 'cardType', getCardType(this.getCard)))
        // 調整付款方式可選
        .then(() => dispatchAction('PaymentInfo', 'AmexUpdatePaymentType'));
    },
  },
};
</script>
