<template>
  <div class="step-wrap has-count">
    <ul class="step-list">
      <li class="step-item" v-bind:class="step1IsActive">
        <span class="number">1</span>
        <span class="label">填寫配送資料</span>
      </li>
      <li class="step-item" v-bind:class="step2IsActive">
        <span class="number">2</span>
        <span class="label">選擇付款方式</span>
      </li>
      <li class="step-item" v-bind:class="step3IsActive">
        <span class="number">3</span>
        <span class="label">訂單送出</span>
      </li>
    </ul>
  </div>
</template>

<script>
const props = {
  step: {
    type: Number,
    default: 0,
  },
};

const computed = {
  /** 目前步驟狀態號 */
  getStep() {
    return this.step;
  },
  /** 判斷是否可加 active class */
  step1IsActive() {
    return {
      active: this.getStep >= 1,
    };
  },
  step2IsActive() {
    return {
      active: this.getStep >= 2,
    };
  },
  step3IsActive() {
    return {
      active: this.getStep >= 3,
    };
  },
  hasProductClass() {
    return {
      'offset bottom gray': !this.isEmpty,
    };
  },
};

export default {
  props,
  computed,
};
</script>
