export const emptyStore = {
  id: '',
  name: '',
  type: '',
  time: '',
  tel: '',
  address: '',
  lat: 0,
  lon: 0,
  serviceItem: '',
};
export const defaultRecipient = {
  employeeId: '',
  name: '',
  cellPhone: '',
  tel: {
    area: '',
    number: '',
    ext: '',
  },
  email: '',
  zip: '',
  city: '',
  cityName: '',
  town: '',
  townName: '',
  address: '',
  store: emptyStore,
  isSave: false,
};

export const getComponent = function (type) {
  if (type === '1') {
    return 'step1-addressee';
  }

  if (type === '2') {
    return 'step1-store';
  }

  return '';
};

export const storeType = [
  {
    type: '2',
    name: '特約服務中心',
  },
  {
    type: '1',
    name: '中華電信櫃台',
  },
  {
    type: '3',
    name: '神腦維修中心',
  },
  {
    type: '14',
    name: 'Apple授權維修中心',
  },
];
/** [配送方式] 與 component 對照表 for step1 */
export const componentLut = {
  1: 'step1-addressee',
  2: 'step1-store',
};
