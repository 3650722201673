<template>
  <div>
    <h3 class="fn-lg blue text-center spacing top md">請同意成為神腦生活會員</h3>
    <div class="border bottom spacing bottom md"></div>
    <h4 class="text-center spacing bottom sm">系統將以您提供的手機號碼</h4>
    <h4 class="text-center spacing top sm bottom sm value fn-lg">{{ mobilePhone }}</h4>
    <h4 class="text-center spacing bottom lg">註冊成為神腦會員</h4>
    <div class="border bottom"></div>
    <register-info :isMobile="true" :name-err-msg="'姓名'" :email-err-msg="'E-Mail'" :sex-err-msg="'性別'" />
    <div class="mb-privacy-block">
      <div class="mb-privacy-modal">
        <div class="head-ex">神腦生活會員服務條款及隱私權政策</div>
        <privacy-terms-content :isMobile="true" />
      </div>
    </div>
    <div class="divider">
      <label>
        <input type="checkbox" v-model="isCheckedAgreement" @change="checkCheckboxStatus" />
        我已詳讀並同意會員條款及隱私權條款
        <p class="error" v-if="validateErrorMsg">
          {{ validateErrorMsg }}
        </p>
      </label>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-disabled btn-round" data-dismiss="modal" @click="$emit('cancel')">
        不同意
      </button>
      <button
        type="button"
        class="btn btn-primary btn-round"
        href="javascript:void(0);"
        @click="verifyAndGetMemberRegistration">
        同意
      </button>
    </div>
  </div>
</template>

<script>
import { createRegistrationItem, sendGA4sEvent } from '@/helpers/tracking/ga';
import { commitMutation, getState, renderState } from '@/helpers/vuex';
import { memberRegistrationProcess } from '@/mixins/cart/finished/member-registration-process';
import PrivacyTermsContent from '@/components/common/modals/register-info-modal/privacy-terms-content.vue';
import RegisterInfo from '@/components/common/modals/register-info-modal/register-info.vue';

const verifyAndGetMemberRegistration = function () {
  this.checkCheckboxStatus();
  this.$validator.validateAll().then((result) => {
    if (result && this.isCheckedAgreement) {
      sendGA4sEvent('sign_up_join', createRegistrationItem('註冊資料填寫完成', 'cart'));
      this.$emit('get-member-registration');
    }
  });
};

export default {
  components: {
    PrivacyTermsContent,
    RegisterInfo,
  },
  name: 'step1-member-registration',
  mixins: [memberRegistrationProcess],
  computed: {
    mobilePhone: getState('OrderInfo', 'mobilePhone'),
    validateErrorMsg: getState('RegisterSenaoMembership', 'validateErrorMsg'),
    isCheckedAgreement: {
      get() {
        return renderState('RegisterSenaoMembership', 'isDisabledCheckedAgreement');
      },
      set(value) {
        commitMutation('RegisterSenaoMembership', 'isDisabledCheckedAgreement', value);
      },
    },
  },
  methods: {
    verifyAndGetMemberRegistration,
  },
};
</script>
