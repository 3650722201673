<template>
  <modal
    :isModalStatic="true"
    :isHideHeaderCloseButton="true"
    :isHideFooter="true"
    componentName="RegisterModal"
    bodyCssClasses="plump">
    <template v-slot:body>
      <component
        :is="step"
        ref="registerModal"
        @cancel="closeModal('RegisterModal')"
        @get-member-registration="$emit('get-member-registration')"
        @get-sms-send="$emit('get-sms-send')"></component>
    </template>
  </modal>
</template>

<script>
import { getState } from '@/helpers/vuex';
import Modal from '@/components/common/common-modal.vue';
import Step1 from '@/components/mobile/step2/payment-type/register-into-modal/step1.vue';
import Step2 from '@/components/mobile/step2/payment-type/register-into-modal/step2.vue';
import { closeModal } from '@/helpers/modal';

export default {
  components: {
    Modal,
    Step1,
    Step2,
  },
  name: 'registerInfoModal',
  computed: {
    step: getState('RegisterSenaoMembership', 'step'),
  },
  methods: {
    closeModal,
  },
};
</script>
