<template>
  <panel-template :title="title">
    <template slot="panel-body">
      <!-- 付款人姓名 -->
      <inputRow
        :title="'中文姓名'"
        :notice="true"
        :placeholder="'請填寫中文全名'"
        :subTitle="''"
        :inputName="'payerName'"
        :hookType="'store'"
        :hookStore="['StoreInfo', 'name']"
        :maxlength="6"
        :module="moduleName"
        :validateRule="'required'"
        :isRtype="isRtype"
        :is-show-import-order-info="true"></inputRow>

      <!-- 付款人手機號碼 -->
      <inputRow
        :title="'手機'"
        :notice="true"
        :placeholder="'請填寫手機號碼'"
        :subTitle="''"
        :inputType="'tel'"
        :inputName="'payerPhone'"
        :hookType="'store'"
        :hookStore="['StoreInfo', 'mobilePhone']"
        :maxlength="10"
        :module="moduleName"
        :validateRule="'required|mobile-phone'"
        :isRtype="isRtype"></inputRow>

      <!-- 付款人市話 -->
      <telPhoneRow
        :title="'市話'"
        :notice="false"
        :subTitle="'(範例: 02-12340000 #5678)'"
        :payerAreaName="'payerArea'"
        :payerNumberName="'payerNumber'"
        :payerExtName="'payerExt'"
        :hookType="'store'"
        :hookStoreWithArea="['StoreInfo', 'localPhoneArea']"
        :hookStoreWithNumber="['StoreInfo', 'localPhone']"
        :hookStoreWithExt="['StoreInfo', 'localPhoneExt']"
        :module="moduleName"
        :isRtype="isRtype"></telPhoneRow>

      <!-- 付款人 email -->
      <inputRow
        :title="'Email'"
        :notice="true"
        :placeholder="'請填寫Email'"
        :inputType="'email'"
        :inputName="'payerEmail'"
        :hookType="'store'"
        :hookStore="['StoreInfo', 'email']"
        :maxlength="200"
        :validateRule="'required|custom-email'"
        :module="moduleName"
        :isRtype="isRtype"></inputRow>

      <!-- 付款人地址 -->
      <address-row
        :idKey="'payer'"
        :key="moduleName"
        :module="moduleName"
        :states="stateNames"
        :island="true"
        :isRtype="isRtype"></address-row>
    </template>
  </panel-template>
</template>

<script>
import { renderState } from '@/helpers/vuex';
import addressRow from '@/components/mobile/step2/address-row.vue';
import inputRow from '@/components/mobile/step2/input-row.vue';
import panelTemplate from '@/components/mobile/elements/panel-template.vue';
import telPhoneRow from '@/components/mobile/step2/tel-phone-row.vue';

const components = {
  panelTemplate,
  inputRow,
  telPhoneRow,
  addressRow,
};

const data = function () {
  return {
    title: '付款人資訊',
    /** state 的 module 名稱 */
    moduleName: 'StoreInfo',
    /** address-row 所綁定的 state */
    stateNames: ['addressCityId', 'addressCity', 'addressTownId', 'addressTown', 'addressZip', 'address'],
  };
};

export default {
  name: 'payer-info-without-credit-card',
  inject: ['$validator'],
  components,
  data,
  computed: {
    /** 是 R 身份者 disable 付款人表單 */
    isRtype() {
      return renderState('PaymentInfo', 'isPayerReadOnly');
    },
  },
};
</script>
