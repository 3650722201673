<template>
  <div class="panel">
    <div class="panel-head">
      <h3>{{ getTitle }}</h3>
    </div>
    <div class="panel-body">
      <slot name="panel-body"></slot>
    </div>
  </div>
</template>

<script>
const props = {
  title: {
    type: String,
    default: '',
  },
};

const computed = {
  getTitle() {
    return this.title;
  },
};

export default {
  props,
  computed,
};
</script>
