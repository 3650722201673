<template>
  <component :is="dynamicComponent" :key="dynamicComponent"></component>
</template>

<script>
import { defaultRecipient } from '@/components/mobile/step1/constant';
import Default from '@/components/mobile/step2/payment-type/default-view.vue';
import Installment from '@/components/mobile/step2/bank-installment-list.vue';
import InvoiceDoc from '@/components/mobile/step2/invoice/invoice-doc.vue';
import Uniform from '@/components/mobile/step2/uniform-list.vue';

export default {
  name: 'step2',
  components: {
    /** 選擇分期方式頁 */
    Default,
    /** 分期銀行列表 modal */
    Installment,
    /** 統編記事本列表 modal */
    Uniform,
    /** 電子發票處理流程 modal */
    InvoiceDoc,
    /** 非會員註冊神腦生活 modal */
  },
  data() {
    return {
      recipient: defaultRecipient,
      pages: {
        /** step2表單 (default) */
        form: 'Default',
        /** 分期銀行列表 */
        bank: 'Installment',
        /** 統編記事本列表 */
        uniform: 'Uniform',
        /** 電子發票處理流程 */
        invoice: 'InvoiceDoc',
      },
    };
  },
  computed: {
    dynamicComponent() {
      const page = this.$store.getters['MobileCartStep2/getPage'] || 'form';
      return this.pages[page] || this.pages.form;
    },
  },
};
</script>
