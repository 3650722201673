<template>
  <div class="container">
    <header-cart>
      <template slot="header-middle">統編記事本</template>
      <template slot="header-left">
        <a class="btn-link" href="javascript:void(0);" @click="hideBankList">
          <i class="icon icon-arrow-left"></i>
        </a>
      </template>
    </header-cart>
    <!-- if Invoice data empty -->
    <main v-if="!hasInvoice" class="white">
      <h4 class="content">尚未設定統編資訊</h4>
      <div class="btn-group">
        <a href="javascript:void(0);" class="btn btn-primary btn-round" @click="hideBankList"> 關閉 </a>
      </div>
    </main>
    <!-- has Invoice data> -->
    <main v-if="hasInvoice" class="white">
      <div class="check-table" v-for="(item, index) in list" :key="index">
        <div class="check-cell">
          <div class="checkbox">
            <input
              name="uniform_radio"
              type="radio"
              :id="radioId(index)"
              :value="index"
              @click="changeSelect"
              :checked="ischecked(index)" />
          </div>
          <div class="text">
            <label :for="radioId(index)">
              統編：{{ item.number }}
              <br />
              {{ item.title }}
              <br />
              {{ item.receipt | nameFormat }}
              <br />
              {{ item.address | addressFormat }}
            </label>
          </div>
        </div>
      </div>
      <div class="btn-group">
        <a href="javascript:void(0);" class="btn btn-primary btn-round" @click="copyConfirm"> 確認選取 </a>
      </div>
    </main>
  </div>
</template>

<script>
import { baseCityTown } from '@/helpers/city-town';
import { commitMutation, renderState } from '@/helpers/vuex';
import headerCart from '@/components/mobile/Header/header-cart.vue';

const components = {
  headerCart,
};

const data = function () {
  return {
    // 統編 radio 選取 index
    select: null,
    allCityTownTaiwan: [],
  };
};

const computed = {
  /** 取得統編記事本列表 */
  list() {
    return this.$store.getters['MobileCartStep2/getpageData'] || [];
  },
  /** 判斷統編是否有資料 */
  hasInvoice() {
    return renderState('InvoiceModalInfo', 'invoices').length > 0;
  },
};

const methods = {
  /** 關閉統編記事本列表返回表單頁 */
  hideBankList() {
    return this.$store.dispatch('MobileCartStep2/changePage', {
      page: 'form',
      data: [],
    });
  },
  ischecked(index) {
    return this.select === index;
  },
  radioId(index) {
    return `radio_id_${index}`;
  },
  changeSelect(input) {
    this.select = parseInt(input.target.value, 10);
  },
  /** 選取統編記事本內容 */
  copyConfirm() {
    if (this.select < 0 || this.select === null) {
      return alert('請選擇一個統編');
    }

    /** [allCityTownTaiwan] */
    this.allCityTownTaiwan = baseCityTown.data;
    const uniform = this.list[this.select];

    const city = this.allCityTownTaiwan.cities.filter((v) => v.id === uniform.city)[0] || '';
    const town = city.districts.filter((v) => v.id === uniform.town)[0] || '';

    uniform.cityName = city.name;
    uniform.townName = town.name;
    uniform.zip = town.zip;

    /** 公司統編 */
    commitMutation('InvoiceInfo', 'companyNo', uniform.number);
    /** 公司抬頭 */
    commitMutation('InvoiceInfo', 'companyTitle', uniform.title);
    /** 發票收件人 */
    commitMutation('InvoiceInfo', 'recipient', uniform.receipt);
    /** 縣市 */
    commitMutation('InvoiceInfo', 'addressCityId', uniform.city);
    commitMutation('InvoiceInfo', 'addressCity', uniform.cityName);
    /** 鄉鎮 */
    commitMutation('InvoiceInfo', 'addressTownId', uniform.town);
    commitMutation('InvoiceInfo', 'addressTown', uniform.townName);
    commitMutation('InvoiceInfo', 'addressZip', uniform.zip);
    /** 地址 */
    commitMutation('InvoiceInfo', 'address', uniform.address);

    this.$store.dispatch('MobileCartStep2/setUniformIndex', this.select);
    this.$store.dispatch('MobileCartStep2/setSelectUniform', true);
    return this.hideBankList();
  },
};

const filters = {
  nameFormat(value) {
    return `${value.substr(0, 1)}*${value.substr(2, 3)}`;
  },
  addressFormat(value) {
    return `${value.substr(0, 6)}******${value.substr(12, 60)}`;
  },
};

const mounted = function () {
  this.select = this.$store.getters['MobileCartStep2/getUniformIndex'] || null;
};

export default {
  name: 'uniform-list',
  components,
  data,
  computed,
  methods,
  mounted,
  filters,
};
</script>

<style scoped>
header.fixed {
  z-index: 3100 !important;
}
label {
  width: 100%;
}
h4.content {
  font-weight: normal;
  text-align: center;
  margin: 30px;
}
</style>
