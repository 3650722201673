<template>
  <div class="check-cell-content btl">
    <div class="panel-body">
      <div class="fieldset">
        <input
          type="text"
          placeholder="請輸入自然人憑證條碼"
          autocomplete="off"
          name="id_device_code"
          maxlength="16"
          size="16"
          v-model="value"
          v-validate.initial="'required|invoice-id-device'" />
        <p v-if="errors.has('id_device_code')" class="error">* {{ errors.first('id_device_code') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';

export default {
  name: 'invoice-id-device',
  inject: ['$validator'],
  computed: {
    value: createComputed('InvoiceInfo', 'idDeviceCode'),
  },
};
</script>
