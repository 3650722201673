<template>
  <ul class="has-type-disc fn-sm alert alert-warning-fill">
    <li>
      電子票券將於付款完成取得序號後，將發送通知至
      <b class="notice">付款人</b>
      的
      <b class="notice">Mail</b>
      及
      <b class="notice">手機簡訊</b>
      ，消費時請向店家出示票券號碼使用。
    </li>
    <li>
      若需查看票券訊息，請登入會員，至
      <b class="underline">我的電子票券</b>
      查看。
    </li>
    <li>電子票券僅限信用卡一次付款及門市繳費</li>
  </ul>
</template>

<script>
export default {
  name: 'vtp-desc',
};
</script>
