<template>
  <div class="alert alert-verify">
    <i class="icon icon-lock"></i>
    <p>本站採用SSL256 bits安全加密機制</p>
    <p class="focus">授權處理中，請勿關閉視窗或重整頁面</p>
    <div class="progress">
      <loading-progress :status="getStatus" v-on:updateProgress="updateProgress"></loading-progress>
    </div>
  </div>
</template>

<script>
import LoadingProgress from '@/components/Utils/async/loading-progress.vue';

const components = {
  LoadingProgress,
};

const props = {
  status: '',
  orderProcessClear: {
    type: Boolean,
    default: false,
  },
};

const data = function () {
  return {
    loading: '',
  };
};

const computed = {
  getStatus() {
    return this.loading;
  },
};

const methods = {
  /**
   * 改變狀態
   * @param {String} status status string
   */
  changeProgress(status) {
    this.loading = status;
  },
  /**
   * 回傳進度狀態及目前百分比
   * @param {Object} status 進度 json, { status, progress }
   */
  updateProgress(status) {
    if (this.orderProcessClear === false && status.status === 'play' && status.progress >= 80) {
      this.$emit('changeLoadingStatus', 'pause');
    }
  },
};

const watch = {
  status(value) {
    this.changeProgress(value);
  },
};

export default {
  name: 'progress-bar',
  components,
  props,
  data,
  computed,
  methods,
  watch,
};
</script>
