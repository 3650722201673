<template>
  <panel-template :title="title">
    <template slot="panel-body">
      <div class="check-table paylist">
        <div v-for="(item, index) in invoices" :key="index" :id="`invoice-${index}`">
          <div class="check-cell">
            <div class="checkbox">
              <input
                type="radio"
                name="invoice_type"
                v-model="invoice"
                :value="item.type"
                @change="handleInvoiceChange(index)" />
            </div>
            <div class="text">
              <label :for="item.type" :class="{ selected: item.type === invoice }">
                {{ item.title }}
              </label>
            </div>
          </div>
        </div>
        <div id="append-invoice-detail">
          <component :is="dynamicComponent" :key="dynamicComponent"></component>
        </div>
      </div>

      <!-- 同意條款-->
      <div class="text-bar">
        <label class="notice">
          <input type="checkbox" name="agreeCheck" v-model="agreeCheck" v-validate="'required'" />
          若本人後續辦理退貨作業，同意由神腦國際代為處理發票及銷貨退回證明單，以加速退貨退款作業
        </label>
      </div>
      <!-- 注意事項-->
      <p class="divider text-muted">
        * 依統一發票使用辦法規定，個人戶無法換開為公司戶發票，公司戶發票也無法換為個人戶發票
        <a href="javascript:void(0);" class="underline" @click="openInvoiceDoc"> (財政部電子發票流程說明) </a>
      </p>
      <p class="divider text-muted">* 若為預購商品，發票將於商品出貨後再行開立</p>
    </template>
  </panel-template>
</template>

<script>
import { createComputed, renderState } from '@/helpers/vuex';
import addressSelector from '@/components/mobile/step1/form-item/address-selector.vue';
import invoiceDonation from '@/components/mobile/step2/invoice/invoice-donation.vue';
import invoiceIdDevice from '@/components/mobile/step2/invoice/invoice-id-device.vue';
import invoicePaper from '@/components/mobile/step2/invoice/invoice-paper.vue';
import invoicePhone from '@/components/mobile/step2/invoice/invoice-phone.vue';
import panelTemplate from '@/components/mobile/elements/panel-template.vue';

/** 能開立發票方式 */
const invoices = function () {
  /** 從 API 傳來能開立發票的代號 */
  const allowInvoice = renderState('InvoiceInfo', 'invoiceType');
  /** 篩選出 invoiceMobileAll 有對應到的 obj.type 並印出 */
  const invoicesType = this.invoiceMobileAll.filter((x) => allowInvoice.indexOf(x.type) > -1);

  return invoicesType;
};

const invoiceMobileAll = [
  { type: '01', title: '神腦會員載具', component: '' },
  { type: '05', title: '公司發票(郵寄)', component: 'invoice-paper' },
  { type: '02', title: '手機條碼載具', component: 'invoice-phone' },
  { type: '04', title: '發票捐贈', component: 'invoice-donation' },
  { type: '03', title: '自然人憑證條碼載具', component: 'invoice-id-device' },
];

const components = {
  panelTemplate,
  invoicePhone,
  invoiceIdDevice,
  invoiceDonation,
  invoicePaper,
  addressSelector,
};

const data = function () {
  return {
    title: '發票選項',
    invoiceMobileAll,
  };
};

const computed = {
  /** 寫入預設開立發票的類型 */
  invoice: createComputed('InvoiceInfo', 'invoiceDefault'),
  /** 動態切換所選的開立發票方式 */
  dynamicComponent() {
    return this.invoiceMobileAll.filter((v) => v.type === this.invoice)[0].component || '';
  },
  agreeCheck: createComputed('PaymentInfo', 'agreeCheck'),
  /** 能開立發票方式 */
  invoices,
};

const methods = {
  openInvoiceDoc() {
    this.$store.dispatch('MobileCartStep2/changePage', {
      page: 'invoice',
      data: [],
    });
  },
  handleInvoiceChange(index) {
    const invoiceDetail = document.getElementById('append-invoice-detail');
    const currentIndex = index + 1;
    const checkAppendBlock = currentIndex % 2 === 0 || currentIndex === this.invoices.length;
    const getPaymentIndexId = checkAppendBlock ? index : currentIndex;
    const currentDynamicComponent = document.getElementById(`invoice-${getPaymentIndexId}`);
    currentDynamicComponent.appendChild(invoiceDetail);
  },
};

export default {
  name: 'invoice-method',
  inject: ['$validator'],
  components,
  data,
  computed,
  methods,
};
</script>
