<template>
  <div class="container">
    <header-cart>
      <template slot="header-middle">電子發票處理流程</template>
      <template slot="header-left">
        <a class="btn-link" href="javascript:void(0);" @click="hideInvoiceDoc">
          <i class="icon icon-close"></i>
        </a>
      </template>
    </header-cart>
    <div id="eInvoiceFlowChart">
      <div class="notice-block-container">
        <div class="notice-block-header"></div>
        <div class="notice-content">
          <div class="einvoice-chart">
            <p class="spacing bottom">
              本公司依據電子發票實施作業要點第21條規定，有提供買受人得以共通性載具索取雲端發票，以下就會員載具流程及機制說明：於神腦生活消費開立之統一發票資訊，將上傳至
              財政部電子發票整合平台 留存。 消費者可利用財政部電子發票整合平台及「查詢訂單」查詢消費紀錄。
            </p>
            <h4>一、電子發票歸戶及對奬流程說明：</h4>
            <div class="w100">
              <img src="/images/einvoice/einvoice-chart-1.jpg" alt="電子發票歸戶及對奬流程說明圖-1" :class="imgClass" />
            </div>

            <h4>二、電子發票索取流程說明：</h4>
            <img src="/images/einvoice/einvoice-chart-2.jpg" alt="電子發票歸戶及對奬流程說明圖-2" :class="imgClass" />
            <h4>三、將神腦生活會員載具歸戶到共通性載具：</h4>
            <p>
              您亦可透過
              <a class="underline" href="https://www.einvoice.nat.gov.tw/index" target="_blank">
                電子發票整合服務平台
              </a>
              將您的神腦生活會員載具歸戶到所屬的共通性載具中，流程如下：
            </p>
            <img src="/images/einvoice/einvoice-chart-3.jpg" alt="電子發票歸戶及對奬流程說明圖-3" :class="imgClass" />
            <p class="spacing bottom">
              ※ 前往財政部電子發票整合平台
              <a class="underline" href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC501W/" target="_blank">
                申請共通性載具
              </a>
            </p>
            <h4>四、發票開立說明：</h4>
            <ul class="spacing bottom">
              <li>
                在您付款完成、出貨後，
                <b>神腦生活</b>
                會開立電子發票(電子發票-公司／電子發票-個人，已索取紙本除外)，並寄MAIL通知您，您可利用
                <a class="underline" href="https://www.einvoice.nat.gov.tw/index" target="_blank">
                  財政部電子發票整合服務平台
                </a>
                查詢消費紀錄，如您需要發票正本，可於單月10日前至「會員中心」→「訂單查詢」→「購買證明」頁面
                點選「申請紙本發票」索取，確認收件人姓名／地址等資料後，
                <b>神腦生活</b>
                會將發票列印寄出至您指定地址。
              </li>
              <li>
                如您於訂購時選擇將發票捐贈，依據法令規定，已捐贈的發票無法索取紙本發票及更改捐贈對象，如有退貨需求，
                <b>神腦生活</b>
                會將該發票作廢。
              </li>
              <li>
                電子發票-公司 (有打統編者)，將於交易完成，且確認無退貨需求後，列印寄出至訂購時所指定的發票寄送地址。
              </li>
            </ul>
            <h4>五、「電子發票實施作業要點」說明：</h4>
            <p>
              本作業辦法，全文請見電子發票作業平台公告之內文：
              <a class="underline" href="http://law-out.mof.gov.tw/LawContent.aspx?id=FL041411" target="_blank">
                「電子發票實施作業要點」
              </a>
              <br />
              <br />
              有關電子發票作業可參見
              <a class="underline" href="https://www.einvoice.nat.gov.tw/index" target="_blank">
                財政部電子發票整合服務平台
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCart from '@/components/mobile/Header/header-cart.vue';

export default {
  data() {
    return {
      imgClass: { 'img-class': true },
    };
  },
  methods: {
    /** 關閉電子發票處理流程 modal */
    hideInvoiceDoc() {
      return this.$store.dispatch('MobileCartStep2/changePage', {
        page: 'form',
        data: [],
      });
    },
  },
  components: {
    headerCart,
  },
};
</script>

<style scoped>
.w100 {
  width: 100%;
}

.img-class {
  width: 100%;
  max-width: 750px;
  display: block;
  margin: auto;
}
</style>
