<template>
  <div class="check-cell-content">
    <div class="pane-body">
      <div class="fieldset">
        <a v-if="isLoginUser" class="underline link_b" href="javascript:void(0);" @click="showUniforms">
          使用統編記事本
        </a>
        <input
          type="text"
          placeholder="請輸入公司統編"
          name="order_invoice_tax_id"
          maxlength="8"
          size="8"
          autocomplete="off"
          v-model="number"
          v-validate.initial="'required|tax-id-number'" />
        <p v-if="errors.has('order_invoice_tax_id')" class="error">* {{ errors.first('order_invoice_tax_id') }}</p>
      </div>
      <div class="fieldset">
        <input
          type="text"
          placeholder="請輸入公司抬頭"
          name="order_invoice_title"
          minlength="3"
          maxlength="50"
          autocomplete="off"
          v-model="title"
          v-validate.initial="'required'" />
        <p v-if="errors.has('order_invoice_title')" class="error">* {{ errors.first('order_invoice_title') }}</p>
      </div>
      <div class="fieldset">
        <input
          type="text"
          placeholder="請輸入發票收件人"
          name="order_invoice_name"
          minlength="2"
          maxlength="20"
          autocomplete="off"
          v-model="receipt"
          v-validate.initial="'required'" />
        <p v-if="errors.has('order_invoice_name')" class="error">* {{ errors.first('order_invoice_name') }}</p>
      </div>
      <!-- address -->
      <address-row :idKey="'invoice'" :module="moduleName" :states="stateNames" :island="true" />
      <p class="divider">
        <label v-if="isLoginUser" class="notice">
          <input type="checkbox" id="setAlwaysUniform" name="setAlwaysUniform" v-model="checkbox" />
          設定為常用統編記事本
        </label>
      </p>
      <p class="divider text-muted">* 電子發票-公司(紙本)將於鑑賞期過後寄出</p>
    </div>
  </div>
</template>

<script>
import { compose, gt, __, prop } from 'ramda';
import { createComputed, getState, renderState, getAction, commitMutation } from '@/helpers/vuex';
import { isLoginUser } from '@/helpers/is-from-app';
import addressRow from '@/components/mobile/step2/address-row.vue';

/**  vuex 裡的 invoices 的長度大於 0 則返回 true */
const hasInvoiceFn = compose(gt(__, 0), prop('length'), getState('InvoiceModalInfo', 'invoices'));

const showUniforms = function () {
  /** 如果有統編資料或有打過[統編記事本] API */
  if (hasInvoiceFn() || this.hasLoadInvoice) {
    return this.$store.dispatch('MobileCartStep2/changePage', {
      page: 'uniform',
      data: renderState('InvoiceModalInfo', 'invoices'),
    });
  }
  /** 沒打過 [統編記事本] API 的情境 */
  if (!this.hasLoadInvoice) {
    /** load [統編記事本] API */
    getAction('InvoiceModalInfo', 'getInvoices')().then(() => {
      /** 寫入已 load 過[統編記事本] API 的紀錄 */
      commitMutation('InvoiceModalInfo', 'hasLoadInvoice', true);
      return this.$store.dispatch('MobileCartStep2/changePage', {
        page: 'uniform',
        data: renderState('InvoiceModalInfo', 'invoices'),
      });
    });
  }
  return false;
};

const getUniform = function () {
  const uniform = this.$store.getters['MobileCartStep2/getUniform'] || {};
  return uniform;
};

export default {
  name: 'invoice-paper',
  inject: ['$validator'],
  components: {
    addressRow,
  },
  data() {
    return {
      /** state 的 module 名稱 */
      moduleName: 'InvoiceInfo',
      /** address-row 所綁定的 state */
      stateNames: ['addressCityId', 'addressCity', 'addressTownId', 'addressTown', 'addressZip', 'address'],
    };
  },
  computed: {
    required() {
      return {
        is_not: 0,
      };
    },
    number: createComputed('InvoiceInfo', 'companyNo'),
    title: createComputed('InvoiceInfo', 'companyTitle'),
    receipt: createComputed('InvoiceInfo', 'recipient'),
    zip: createComputed('InvoiceInfo', 'addressZip'),
    cityId: createComputed('InvoiceInfo', 'addressCityId'),
    city: createComputed('InvoiceInfo', 'addressCity'),
    townId: createComputed('InvoiceInfo', 'addressTownId'),
    town: createComputed('InvoiceInfo', 'addressTown'),
    address: createComputed('InvoiceInfo', 'address'),
    /** 設定為常用統編記事本 */
    checkbox: createComputed('InvoiceInfo', 'alwaysUniform'),
    /** 是否有打過 [統編記事本] API] */
    hasLoadInvoice: getState('InvoiceModalInfo', 'hasLoadInvoice'),
    isLoginUser,
  },
  methods: {
    showUniforms,
    getUniform,
  },
};
</script>
