<template>
  <div :class="{ 'check-cell-content btl': !isRtype }">
    <creditcard-info :is-rtype="isRtype"></creditcard-info>
  </div>
</template>

<script>
import CreditcardInfo from '@/components/mobile/step2/payment-type/common/creditcard-info.vue';

export default {
  name: 'creditcard-onetime',
  inject: ['$validator'],
  props: {
    isRtype: Boolean,
  },
  components: {
    CreditcardInfo,
  },
  mounted() {
    this.$validator.validate('*');
  },
};
</script>
